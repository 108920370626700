<template>
  <svg
    viewBox="0 0 47 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M53.408 47C54.84 47 56 48.137 56 49.54v4.168C56 59.392 51.297 64 45.496 64H19.504C13.703 64 9 59.392 9 53.708V49.54C9 48.137 10.16 47 11.592 47c1.432 0 2.592 1.137 2.592 2.54v4.168c0 2.879 2.382 5.213 5.32 5.213h25.992c2.939 0 5.32-2.334 5.32-5.213V49.54c0-1.403 1.161-2.54 2.592-2.54zM33 0c7.72 0 14 6.315 14 14.077l.001 8.029c5.088.722 8.999 5.05 8.999 10.281v6.05C56 39.852 54.84 41 53.408 41c-1.432 0-2.592-1.148-2.592-2.563v-6.05c0-2.906-2.382-5.26-5.32-5.26H19.503c-2.939 0-5.32 2.354-5.32 5.26v6.05c0 1.415-1.161 2.563-2.592 2.563S9 39.852 9 38.437v-6.05c0-5.57 4.433-10.116 10-10.375v-7.935C19 6.315 25.28 0 33 0zm0 5.231c-4.85 0-8.798 3.969-8.798 8.846V22h17.595v-7.923c0-4.877-3.945-8.846-8.797-8.846z"
      transform="translate(-9)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLock'
};
</script>
