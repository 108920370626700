<template>
  <article class="accordion" :class="{ 'pb-20': active }">
    <button
      class="font-walsheim font-bold py-20 flex justify-between items-center w-full text-left focus:outline-none focus:text-blue-dark"
      :title="`toggle accordion content for ${title}`"
      :aria-controls="`accordion ${title}`"
      :aria-expanded="this.active.toString()"
      @click="toggle()"
    >
      <span>{{ title }}</span>
      <component
        :is="currentIcon"
        aria-hidden="true"
        class="text-blue-dark ml-16 flex-shrink-0 w-20"
        :class="{ 'transform rotate-180': active }"
      />
    </button>
    <div v-show="active" :id="`accordion ${title}`" class="mt-10">
      <slot />
    </div>
  </article>
</template>

<script>
import ArrowIcon from '@/components/svg/IconArrow.vue';
import { sendGaEvent } from '@/utils';

export default {
  name: 'AccordionComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    topBorder: Boolean
  },
  data() {
    return {
      active: false
    };
  },
  computed: {
    currentIcon: () => ArrowIcon
  },
  methods: {
    toggle() {
      this.active = !this.active;
      const eventAction = this.active ? 'Open' : 'Close';
      sendGaEvent('Accordion', 'Accordion', eventAction, `Click/${this.title}`);
    }
  }
};
</script>

<style>
.accordion {
  @apply overflow-hidden border-t border-blue-dark;
}
</style>
