<template>
  <main>
    <div class="container pt-40 lg:pt-50 xl:pt-60">
      <h1 class="heading--headline mb-15 lg:mb-20">{{ pageTitle }}</h1>
      <div class="column-row-group mb-30 lg:mb-50">
        <div class="column-row lg:w-5/12 xl:w-4/12 mb-20 lg:mb-0" v-html="resultText"></div>
        <div class="column-row lg:w-3/12 xl:w-2/12 mb-20 lg:mb-0">
          <label for="sort-results" class="inline-block font-bold mb-12">Sort by:</label>
          <div class="relative w-full">
            <select
              name="sort"
              id="sort-results"
              class="select-filter option-text w-full"
              v-model="sortOption"
              @change="getFirstPage()"
            >
              <option :value="1">Relevance</option>
              <option :value="2">Latest Date</option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
        <div class="column-row flex items-end lg:w-4/12 xl:ml-auto">
          <form
            class="text-blue-dark border border-gray-500 rounded-24 pt-10 pb-12 px-20 flex w-full"
            @submit.prevent="getFirstPage()"
          >
            <input
              v-model="searchTerm"
              type="text"
              name="search"
              placeholder="Search..."
              class="leading-none w-full focus:outline-none focus:bg-gray-300"
            />
            <button><IconSearch class="w-16" @click="getFirstPage()" /></button>
          </form>
        </div>
      </div>
      <div class="column-row-group">
        <div class="column-row w-6/12 md:w-3/12 lg:w-2/12">
          <button
            class="py-20 lg:pt-30 lg:pb-24 rounded-t-30 font-gotham font-medium flex justify-center items-center w-full"
            :class="showResources ? 'bg-blue-dark text-white' : 'bg-gray-200 text-blue-dark'"
            @click="setShowResources(false)"
          >
            Content
          </button>
        </div>
        <div class="column-row w-6/12 md:w-3/12 lg:w-2/12">
          <button
            class="py-20 lg:pt-30 lg:pb-24 rounded-t-30 font-gotham font-medium flex justify-center items-center w-full"
            :class="showResources ? 'bg-gray-200 text-blue-dark' : 'bg-blue-dark text-white'"
            @click="setShowResources(true)"
          >
            Resources
          </button>
        </div>
      </div>
    </div>
    <div class="search__results">
      <div class="container">
        <template v-if="showResources">
          <RelatedResource
            v-for="resource in pages"
            :key="resource.id"
            :colour="resource.background_colour"
            :description="resource.intro"
            :fileCount="resource.document_count"
            :imageAlt="resource.card_image ? resource.card_image.alt : ''"
            :imageSrc="resource.card_image ? resource.card_image.url : ''"
            :publishDate="resource.publish_date"
            :title="resource.title"
            :topic="resource.topic"
            :url="resource.url"
            class="mb-30 md:mb-40"
          />
        </template>
        <template v-else>
          <RelatedArticle
            v-for="page in pages"
            :key="page.id"
            :colour="page.background_colour"
            :description="page.intro"
            :imageAlt="page.card_image ? page.card_image.alt : ''"
            :imageSrc="page.card_image ? page.card_image.url : ''"
            :publishDate="page.type === 'article page' ? page.publish_date : null"
            :title="page.title"
            :topic="page.type === 'article page' ? page.topic : null"
            :url="page.url"
            class="mb-30 md:mb-40"
          />
        </template>
        <hr class="border-gray-400 border-t mb-30 md:mb-40" />
        <div class="column-row-group lg:justify-end items-end">
          <div class="column-row lg:w-4/12 mb-30 lg:mb-0">
            <ButtonRound
              v-if="next"
              colour="white"
              size="large"
              :text="buttonText"
              class="w-full"
              :disabled="loading"
              @click.native="loadMore()"
            />
          </div>
          <div class="column-row lg:w-4/12">
            <BackToTop />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import BackToTop from '@/components/global/BackToTop.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import RelatedArticle from '@/components/cards/RelatedArticle.vue';
import RelatedResource from '@/components/cards/RelatedResource.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import IconSearch from '@/components/svg/IconSearch.vue';

export default {
  name: 'SiteSearch',
  components: {
    BackToTop,
    ButtonRound,
    RelatedArticle,
    RelatedResource,
    IconArrow,
    IconSearch
  },
  data() {
    return {
      error: false,
      firstLoad: true,
      loading: false,
      next: false,
      pageNum: 1,
      pages: [],
      queryString: '',
      searchTerm: '',
      showResources: false,
      sortOption: 1,
      totalCount: 0
    };
  },
  computed: {
    buttonText() {
      return this.loading ? 'Loading...' : 'Load More';
    },
    pageTitle() {
      const results = this.pages.length ? ' results' : '';
      return `Search${results}`;
    },
    resultText() {
      if (this.error) {
        return '<p>An unexpected error occurred. Please refresh the page and try again.</p>';
      }
      if (!this.pages.length) return '';
      const plural = this.pages.length > 1 ? 's' : '';
      return `<p>Showing ${this.totalCount} result${plural} for <strong>'${this.searchTerm}'</strong></p>`;
    }
  },
  created() {
    this.getQuery();
  },
  methods: {
    getFirstPage() {
      this.updateQueryAndUrl();
      if (!this.queryString) {
        this.pages = [];
        return;
      }

      this.loading = true;
      this.next = true;
      this.pageNum = 1;
      this.pages = 0;
      this.totalCount = 0;

      axios
        .get(
          `${process.env.VUE_APP_SITE_DOMAIN}search-results/${this.queryString}&page=${this.pageNum}`
        )
        .then(response => {
          this.pages = response.data.items;
          this.next = response.data.next;
          this.totalCount = response.data.total_count;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          console.error('Failed to fetch search results.');
        });
    },
    getQuery() {
      const params = new URLSearchParams(window.location.search);
      this.searchTerm = params.get('search') || '';

      if (this.searchTerm) {
        this.getFirstPage();
      }
    },
    loadMore() {
      this.pageNum += 1;
      axios
        .get(
          `${process.env.VUE_APP_SITE_DOMAIN}search-results/${this.queryString}&page=${this.pageNum}`
        )
        .then(response => {
          this.pages = this.pages.concat(response.data.items);
          this.next = response.data.next;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          console.error('Failed to fetch additional search results.');
        });
    },
    setShowResources(state) {
      this.showResources = state;
      this.getFirstPage();
    },
    updateQueryAndUrl() {
      if (!this.searchTerm) {
        // Early exit if search has been cleared
        this.queryString = '';
        window.history.replaceState({}, '', window.location.pathname);
        return '';
      }

      const params = new URLSearchParams();
      params.set('search', this.searchTerm);
      params.set('order', this.sortOption);
      if (this.showResources) params.set('resources', this.showResources);

      this.queryString = `?${params.toString()}`;

      if (this.firstLoad) {
        window.history.replaceState({}, '', this.queryString);
      } else {
        this.firstLoad = false;
      }
      return this.queryString; // Return value for tests
    }
  }
};
</script>

<style>
.search__results {
  @apply bg-gray-200 rounded-t-30 pt-30 pb-40;

  @screen lg {
    @apply pt-40 pb-60;
  }

  @screen xl {
    @apply rounded-t-60 pt-60 pb-80;
  }
}
</style>
