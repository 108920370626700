<template>
  <div>
    <div class="flex items-start border-b border-gray-400 px-20 md:px-24 lg:px-0">
      <a :href="page.url" class="w-full py-20 focus:underline hover:underline" :class="indentation">
        {{ page.title }}
      </a>
      <button
        v-if="children.length"
        class="box-content w-20 h-20 pl-10 py-20"
        @click="toggleChildren"
      >
        <component :is="buttonIcon" class="text-blue-dark" :class="iconClasses" />
      </button>
    </div>
    <SideNavChild
      v-for="childPage in children"
      :key="childPage.id"
      :depth="depth + 1"
      :page="childPage"
      :class="{ hidden: !showChildren }"
    />
  </div>
</template>

<script>
import IconCross from '@/components/svg/IconCross.vue';
import IconMinus from '@/components/svg/IconMinus.vue';

export default {
  name: 'SideNavChild',
  props: {
    depth: {
      type: Number,
      default: 0
    },
    page: {
      type: Object, // id, title, url properties
      required: true
    }
  },
  data() {
    return {
      showChildren: false
    };
  },
  computed: {
    buttonIcon() {
      return this.showChildren ? IconMinus : IconCross;
    },
    children() {
      if (!this.page.children || this.depth >= 2) return [];
      return this.page.children;
    },
    iconClasses() {
      return {
        'w-20': this.showChildren,
        'w-14 transform rotate-45': !this.showChildren
      };
    },
    indentation() {
      if (this.depth === 0) return '';
      if (this.depth === 1) return 'pl-20';
      return 'pl-40';
    }
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    }
  }
};
</script>

<style></style>
