<template>
  <div class="overflow-x-hidden">
    <div class="container relative">
      <div
        class="page-header__shape--main header-image--height lg:flex lg:justify-center pl-60 lg:pl-0 pt-30 md:pt-60"
        :class="shapeColour"
      >
        <img v-bind="image" sizes="100vw" class="resource-header__image" />
      </div>
      <div class="header-image--height page-header__shape--secondary" :class="shapeColour"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourcePageHeader',
  props: {
    colour: {
      type: String,
      default: 'blue'
    },
    image: {
      type: Object, // "srcset", "src", "alt" properties
      required: true
    }
  },
  computed: {
    shapeColour() {
      return `bg-${this.colour}`;
    }
  }
};
</script>

<style>
.resource-header__image {
  @apply h-full w-full object-cover object-left-top shadow-light;

  @screen md {
    @apply ml-60;
  }

  @screen lg {
    @apply ml-0;
    width: 66%;
  }
}
</style>
