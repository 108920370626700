// Utility functions

/**
 * Send a Google Analytics event with the provided parameters
 * @param {String} event - The event name
 * @param {String} category - The event category
 * @param {String} action - The action taken by the user
 * @param {String} label - Miscellaneous further information
 */
const sendGaEvent = (event, eventCategory, eventAction, eventLabel) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      eventCategory,
      eventAction,
      eventLabel
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { sendGaEvent };
