<template>
  <svg
    viewBox="0 0 64 47"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M48.183 8C56.905 8 64 15.003 64 23.61c0 5.393-3.086 11.43-9.174 17.946-6.062 6.487-13.047 11.269-15.768 13.022-.443.286-.94.422-1.432.422a2.632 2.632 0 01-2.2-1.174 2.57 2.57 0 01.763-3.584C47.056 43.24 58.753 31.917 58.753 23.61c0-5.752-4.742-10.431-10.57-10.431-3.358 0-6.441 1.523-8.46 4.178a2.646 2.646 0 01-3.673.518 2.567 2.567 0 01-.525-3.625C38.545 10.278 43.158 8 48.183 8zM15.817 8c5.024 0 9.638 2.278 12.657 6.25a2.567 2.567 0 01-.524 3.625 2.646 2.646 0 01-3.672-.518c-2.02-2.655-5.103-4.178-8.461-4.178-5.828 0-10.57 4.68-10.57 10.431 0 8.307 11.697 19.629 22.564 26.632a2.57 2.57 0 01.762 3.584 2.632 2.632 0 01-2.2 1.174c-.491 0-.988-.136-1.431-.422-2.722-1.753-9.707-6.535-15.768-13.022C3.086 35.04 0 29.003 0 23.61 0 15.003 7.095 8 15.817 8z"
      transform="translate(0 -8)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHeart'
};
</script>
