<template>
  <article class="column-row-group">
    <div class="column-row mb-16 md:mb-0 md:w-6/12 lg:w-5/12">
      <div class="relative pb-16-9 overflow-hidden rounded-30">
        <a
          :href="url"
          :title="title"
          class="absolute inset-0 px-30 pt-20 lg:pt-30 focus:opacity-90 hover:opacity-90"
          :class="colourClass"
        >
          <img
            :src="imageSrc"
            :alt="imageAlt"
            class="w-full h-full object-cover object-left-top shadow-light"
          />
        </a>
      </div>
    </div>
    <div
      class="column-row md:w-6/12 lg:w-7/12 lg:flex lg:flex-wrap lg:justify-between lg:items-center"
    >
      <div class="flex flex-col mb-16 md:mb-10 lg:mb-20 w-full">
        <p class="text-14 mb-10">{{ subheading }}</p>
        <h3 class="heading--section mb-10">{{ title }}</h3>
        <div data-test="description" v-html="description" />
      </div>
      <p class="font-bold">{{ fileCountString }}</p>
      <ButtonRound size="small" text="Read more" :url="url" class="mt-16 md:mt-10 lg:mt-0" />
    </div>
  </article>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'RelatedResource',
  components: { ButtonRound },
  props: {
    colour: String,
    description: String,
    fileCount: Number,
    imageAlt: String,
    imageSrc: String,
    publishDate: String,
    title: String,
    topic: String,
    url: String
  },
  computed: {
    colourClass() {
      return `bg-${this.colour}`;
    },
    fileCountString() {
      const plural = this.fileCount === 1 ? '' : 's';
      return `x ${this.fileCount} PDF file${plural}`;
    },
    subheading() {
      if (this.topic) return `${this.topic} — ${this.publishDate}`;
      return this.publishDate;
    }
  }
};
</script>
