<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M51.247 59.7a2.64 2.64 0 01-2.64-2.64v-8.39a4.55 4.55 0 00-4.544-4.545l-7.095.005h-.002a2.64 2.64 0 01-1.086-5.045 9.433 9.433 0 10-7.762-.003 2.64 2.64 0 01-1.09 5.043h-7.086a4.55 4.55 0 00-4.545 4.546v8.39a2.639 2.639 0 11-5.278 0v-8.39a9.836 9.836 0 019.788-9.825 14.71 14.71 0 1124.193 0 9.836 9.836 0 019.787 9.825v8.39a2.64 2.64 0 01-2.64 2.64zM2.639 35.6A2.64 2.64 0 010 32.96v-5.393a7.425 7.425 0 015.605-7.19A11.047 11.047 0 0124.725 9.36a2.639 2.639 0 11-4.434 2.865 5.767 5.767 0 10-7.523 8.232 2.64 2.64 0 01-1.229 4.975H7.413a2.137 2.137 0 00-2.135 2.135v5.393a2.64 2.64 0 01-2.639 2.64zM61.36 35.6a2.64 2.64 0 01-2.639-2.64v-5.393a2.136 2.136 0 00-2.135-2.135H52.46a2.64 2.64 0 01-1.23-4.974 5.768 5.768 0 10-7.522-8.233 2.64 2.64 0 01-4.433-2.865 11.047 11.047 0 0119.12 11.017A7.427 7.427 0 0164 27.567v5.393a2.64 2.64 0 01-2.64 2.64z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconGroup'
};
</script>
