<template>
  <svg
    viewBox="0 0 24 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M12.239 4c1.294.006 7.465.054 9.138.503a3.011 3.011 0 012.121 2.13c.441 1.651.495 4.879.501 5.637v.324c-.006.758-.06 3.986-.5 5.637a3.012 3.012 0 01-2.122 2.13c-1.673.45-7.844.497-9.138.502h-.478c-1.294-.005-7.465-.053-9.138-.502a3.012 3.012 0 01-2.121-2.13C.06 16.58.007 13.352 0 12.594v-.324c.006-.758.06-3.986.5-5.637a3.011 3.011 0 012.122-2.13c1.673-.45 7.844-.497 9.138-.502zM10 9v7l6-3.5L10 9z"
      transform="translate(0 -4)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconYoutube'
};
</script>
