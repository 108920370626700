<template>
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 25.23 25.23"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="instagram">
        <g>
          <g>
            <path
              fill="#4b4f54"
              d="m19.93,15.65c-.12,2.68-1.61,4.16-4.28,4.28-.78.03-1.03.04-3.03.04s-2.25,0-3.03-.04c-2.68-.12-4.16-1.61-4.28-4.28-.04-.78-.04-1.03-.04-3.03s0-2.25.04-3.03c.12-2.67,1.61-4.16,4.28-4.28.78-.04,1.03-.04,3.03-.04s2.25,0,3.03.04c2.67.12,4.16,1.61,4.28,4.28.04.78.04,1.04.04,3.03s0,2.25-.04,3.03M12.61,0C5.65,0,0,5.65,0,12.61s5.65,12.61,12.61,12.61,12.61-5.65,12.61-12.61S19.58,0,12.61,0"
            />
            <path
              fill="#4b4f54"
              d="m17.1,9.14c-.56,0-1.02-.45-1.02-1.01s.45-1.02,1.02-1.02,1.01.45,1.01,1.02-.45,1.01-1.01,1.01"
            />
            <path
              fill="#4b4f54"
              d="m12.61,15.43c-1.56,0-2.82-1.26-2.82-2.82s1.26-2.82,2.82-2.82,2.82,1.26,2.82,2.82-1.26,2.82-2.82,2.82m0-7.16c-2.4,0-4.35,1.95-4.35,4.35s1.95,4.35,4.35,4.35,4.35-1.95,4.35-4.35-1.95-4.35-4.35-4.35"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInsta'
};
</script>
