<template>
  <main>
    <div class="container pt-40 lg:pt-50 xl:pt-60">
      <h1 class="heading--headline text-gray-600 mb-15 lg:mb-20">{{ title }}</h1>
      <div class="column-row-group justify-between mb-30 lg:mb-40">
        <div class="column-row mb-20 lg:mb-40 lg:w-8/12 xl:w-6/12" v-html="intro"></div>
        <div class="column-row flex items-end mb-20 lg:mb-40 lg:w-4/12">
          <form
            class="text-blue-dark border border-gray-500 rounded-24 pt-10 pb-12 px-20 flex w-full"
            @submit.prevent="getFirstPage()"
          >
            <input
              v-model="search"
              type="text"
              name="search"
              placeholder="Search..."
              class="leading-none w-full focus:outline-none focus:bg-gray-300"
            />
            <button><IconSearch class="w-16" @click="getFirstPage()" /></button>
          </form>
        </div>
        <div
          class="column-row flex flex-col justify-end lg:w-9/12 xl:w-8/12 mb-20 lg:mb-30 xl:mb-0"
        >
          <h2 class="font-walsheim font-bold mb-10">Quick Filters</h2>
          <div class="column-row-group -mx-4 lg:-mx-8 -my-6">
            <div class="column-row w-auto px-4 lg:px-8 py-6">
              <ButtonRound colour="blue" size="medium" text="All" @click.native="resetFilters()" />
            </div>
            <div
              v-for="(quickFilter, index) in quickFilters"
              :key="index"
              class="column-row w-auto px-4 lg:px-8 py-6"
            >
              <ButtonRound
                colour="white"
                size="medium"
                :text="quickFilter.label"
                @click.native="getQuery(quickFilter.query)"
              />
            </div>
          </div>
        </div>
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <h2 class="font-walsheim font-bold mb-14">Filter by:</h2>
          <label for="topic-filters" class="option-text mb-6 lg:mb-10">Topic</label>
          <div class="relative w-full">
            <select
              name="topic"
              id="topic-filters"
              class="select-filter option-text w-full"
              v-model="selectedTopic"
              @change="getFirstPage()"
            >
              <option value="">---</option>
              <option v-for="topic in topicFilters" :key="topic.id" :value="topic.slug">
                {{ topic.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <label for="location-filters" class="option-text mb-6 lg:mb-10">Location</label>
          <div class="relative w-full">
            <select
              name="location"
              id="location-filters"
              class="select-filter option-text w-full"
              v-model="selectedLocation"
              @change="getFirstPage()"
            >
              <option value="">---</option>
              <option v-for="location in locationFilters" :key="location.id" :value="location.slug">
                {{ location.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-t-30 xl:rounded-t-60 bg-gray-200 pt-30 lg:pt-40 pb-40 lg:pb-80">
      <div class="container">
        <RelatedResource
          v-for="resource in resources"
          :key="resource.id"
          :colour="resource.background_colour"
          :description="resource.intro"
          :fileCount="resource.document_count"
          :imageAlt="resource.card_image ? resource.card_image.alt : ''"
          :imageSrc="resource.card_image ? resource.card_image.url : ''"
          :publishDate="resource.meta.first_published_at"
          :title="resource.title"
          :topic="resource.topic_name"
          :url="resource.url"
          class="mb-30 md:mb-40"
        />
        <hr class="border-t border-gray-400 mb-30 lg:mb-40" />
        <div class="column-row-group lg:justify-end items-end">
          <div class="column-row lg:w-4/12 mb-30 lg:mb-0">
            <ButtonRound
              v-if="next"
              colour="white"
              size="large"
              :text="buttonText"
              class="w-full"
              :disabled="loading"
              @click.native="loadMore()"
            />
          </div>
          <div class="column-row lg:w-4/12">
            <BackToTop />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import BackToTop from '@/components/global/BackToTop.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import RelatedResource from '@/components/cards/RelatedResource.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import IconSearch from '@/components/svg/IconSearch.vue';

const API_BASE = `${process.env.VUE_APP_API_DOMAIN}resources/`;
const PAGE_COUNT = 10;

export default {
  name: 'ResourceIndexPage',
  components: {
    BackToTop,
    ButtonRound,
    RelatedResource,
    IconArrow,
    IconSearch
  },
  props: {
    intro: String,
    pageId: Number,
    quickFilters: Array,
    locationFilters: {
      type: Array,
      required: true
    },
    title: String,
    topicFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      firstLoad: true,
      loading: true,
      offset: 0,
      queryString: null,
      resources: [],
      search: '',
      selectedLocation: '',
      selectedTopic: '',
      totalCount: 1
    };
  },
  computed: {
    buttonText() {
      return this.loading ? 'Loading...' : 'Load More';
    },
    next() {
      return this.offset < this.totalCount;
    }
  },
  created() {
    this.getQuery();
  },
  methods: {
    getFirstPage() {
      this.loading = true;
      this.offset = 0;
      this.totalCount = 1;
      this.resources = [];

      this.updateQueryAndUrl();

      axios
        .get(`${API_BASE}?child_of=${this.pageId}&limit=${PAGE_COUNT}${this.queryString}`)
        .then(response => {
          this.totalCount = response.data.meta.total_count;
          this.resources = response.data.items;
          this.offset += PAGE_COUNT;
          this.loading = false;
        })
        .catch(() => {
          console.error('Failed to fetch resources');
        });
    },
    /**
     * Update filters from query. If query not provided, uses browser URL
     */
    getQuery(query) {
      let params;
      if (query) {
        params = new URLSearchParams(query);
      } else {
        params = new URLSearchParams(window.location.search);
      }

      this.search = params.get('search') || '';
      this.selectedLocation = params.get('location') || '';
      this.selectedTopic = params.get('topic') || '';

      this.getFirstPage();
    },
    /**
     * Load an additional page
     */
    loadMore() {
      this.loading = true;
      axios
        // eslint-disable-next-line max-len
        .get(
          `${API_BASE}?child_of=${this.pageId}&limit=${PAGE_COUNT}&offset=${this.offset}${this.queryString}`
        )
        .then(response => {
          this.resources = this.resources.concat(response.data.items);
          this.loading = false;
          this.offset += PAGE_COUNT;
        })
        .catch(() => {
          console.error('Failed to fetch additional resources');
        });
    },
    resetFilters() {
      this.selectedLocation = '';
      this.selectedTopic = '';
      this.getFirstPage();
    },
    updateQueryAndUrl() {
      const params = new URLSearchParams();
      if (this.search) params.set('search', this.search);
      if (this.selectedLocation) params.set('location', this.selectedLocation);
      if (this.selectedTopic) params.set('topic', this.selectedTopic);
      const queryString = params.toString();
      this.queryString = queryString ? `&${queryString}` : '';

      if (!this.firstLoad) {
        if (queryString) {
          window.history.replaceState({}, '', `?${queryString}`);
        } else {
          window.history.replaceState({}, '', window.location.pathname);
        }
      } else {
        this.firstLoad = false;
      }
    }
  }
};
</script>
