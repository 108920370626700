<template>
  <svg
    viewBox="0 0 45 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M22.5 45c1.38 0 2.5 1.18 2.5 2.634v13.732C25 62.821 23.88 64 22.5 64S20 62.82 20 61.366V47.634C20 46.179 21.12 45 22.5 45zM19.917 2.017a2.676 2.676 0 01-1.93 3.252c-7.421 1.889-12.61 8.557-12.643 16.23.032 7.674 5.222 14.343 12.642 16.233a2.674 2.674 0 011.931 3.25 2.673 2.673 0 01-3.247 1.934C7 40.455.204 31.841.005 21.878A2.585 2.585 0 010 21.72v-.441c0-.053.002-.105.005-.157C.204 11.158 7 2.545 16.67.083a2.67 2.67 0 013.247 1.934zM28.33.083C38 2.545 44.796 11.158 44.996 21.123c.003.052.004.104.004.157v.441c0 .052-.001.105-.004.157-.2 9.963-6.997 18.577-16.666 21.038a2.673 2.673 0 01-3.247-1.933 2.674 2.674 0 011.93-3.251c7.422-1.89 12.611-8.558 12.644-16.232-.033-7.674-5.222-14.342-12.643-16.23A2.676 2.676 0 0128.33.082z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSearchUp'
};
</script>
