<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M10.492 58.611h43.016A10.503 10.503 0 0064 48.12v-6.543a2.634 2.634 0 10-5.267 0v6.543a5.23 5.23 0 01-5.225 5.224H10.492a5.23 5.23 0 01-5.225-5.224l.009-6.54a2.633 2.633 0 00-2.63-2.636h-.004a2.634 2.634 0 00-2.633 2.63L0 48.117A10.504 10.504 0 0010.492 58.61zM2.654 33.607a2.634 2.634 0 002.633-2.63l.014-15.102a5.232 5.232 0 015.225-5.22h11.537a5.218 5.218 0 014.329 2.3l2.572 3.807a5.55 5.55 0 004.604 2.445h19.94a5.23 5.23 0 015.225 5.224v6.543a2.634 2.634 0 105.267 0V24.43A10.503 10.503 0 0053.508 13.94h-19.94a.29.29 0 01-.24-.128l-2.572-3.807a10.48 10.48 0 00-8.693-4.617H10.526A10.507 10.507 0 00.034 15.87L.02 30.971a2.634 2.634 0 002.63 2.636z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFolder'
};
</script>
