<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M26.65 64h-7.96A10.598 10.598 0 018.102 53.414V10.587A10.598 10.598 0 0118.689 0h24.424a2.657 2.657 0 110 5.315H18.689a5.278 5.278 0 00-5.272 5.272v42.827a5.278 5.278 0 005.272 5.271h7.96a2.657 2.657 0 110 5.315z"
    />
    <path
      d="M42.484 32.235H21.432a2.657 2.657 0 110-5.314h21.052a2.657 2.657 0 010 5.314zM26.65 21.21h-5.218a2.657 2.657 0 110-5.315h5.218a2.657 2.657 0 010 5.315zM42.484 42.793H21.432a2.657 2.657 0 010-5.314h21.052a2.657 2.657 0 010 5.314zM42.484 53.35H21.432a2.657 2.657 0 010-5.314h21.052a2.657 2.657 0 010 5.315z"
    />
    <path
      d="M45.31 64h-7.96a2.657 2.657 0 110-5.315h7.96a5.278 5.278 0 005.272-5.271V21.582a2.657 2.657 0 015.315 0v31.832A10.598 10.598 0 0145.31 64zM41.634 21.208a3.278 3.278 0 01-2.327-.962l-3.836-3.837a2.657 2.657 0 113.759-3.757l2.405 2.405 9.726-9.726a2.657 2.657 0 013.758 3.757L43.962 20.246a3.286 3.286 0 01-2.328.962z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconForm'
};
</script>
