<template>
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M10.773 17.545C7.039 17.545 4 14.507 4 10.773A6.78 6.78 0 0110.773 4a6.78 6.78 0 016.772 6.773 6.78 6.78 0 01-6.772 6.772m10.934 2.748l-4-4c-.024-.025-.057-.034-.083-.056a8.727 8.727 0 001.92-5.464C19.545 5.935 15.61 2 10.774 2 5.936 2 2 5.935 2 10.773c0 4.836 3.936 8.772 8.773 8.772a8.718 8.718 0 005.464-1.922c.022.026.032.06.056.084l4 4a.997.997 0 001.414 0 .999.999 0 000-1.414"
      transform="translate(-2 -2)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSearch'
};
</script>
