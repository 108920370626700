<template>
  <svg
    viewBox="0 0 17 12"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M8.267 18C10.482 18 12 16.475 12 14.428s-1.436-3.572-3.487-3.572c-.698 0-1.395.2-1.805.682a3.64 3.64 0 011.066-2.287c.575-.642 1.477-1.044 2.667-1.044.697 0 .903.04 1.518.12V6.12A8.31 8.31 0 0010.523 6C6.297 6 4 8.569 4 12.783 4 16.355 6.133 18 8.267 18zm9 0C19.482 18 21 16.475 21 14.428s-1.436-3.572-3.487-3.572c-.698 0-1.395.2-1.805.682a3.64 3.64 0 011.066-2.287c.575-.642 1.477-1.044 2.667-1.044.697 0 .903.04 1.518.12V6.12A8.31 8.31 0 0019.523 6C15.297 6 13 8.569 13 12.783 13 16.355 15.133 18 17.267 18z"
      transform="translate(-4 -6)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconQuote'
};
</script>
