<template>
  <svg
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M6.3 14a.857.857 0 01-.636-.293l-5.4-6a1.08 1.08 0 010-1.414.838.838 0 011.272 0L6.3 11.586 16.464.293a.838.838 0 011.272 0 1.08 1.08 0 010 1.414l-10.8 12A.857.857 0 016.3 14z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTick'
};
</script>
