<template>
  <header class="header">
    <div class="bg-gray-600 w-full py-8">
      <div class="container-wide w-full flex justify-end items-center">
        <a href="#" id="recitemeLaunch" class="header__login-link">
          <span
            class="w-24 h-24 border-2 border-white flex justify-center items-center rounded-full"
          >
            <IconAccessibility class="w-16 h-16 text-white" />
          </span>
          <span class="uppercase ml-8 mr-8">Accessibility</span>
        </a>
        <a href="https://portal.goodsports.com.au/app/auth/login/" class="header__login-link">
          <span
            class="w-24 h-24 border-2 border-white flex justify-center items-center rounded-full"
          >
            <IconUser class="w-16 h-16 text-white" />
          </span>
          <span class="uppercase ml-8">Login</span>
        </a>
      </div>
    </div>
    <div class="container-wide flex items-end pt-16 xls:pt-20 pb-20 xls:pb-30">
      <a href="/" aria-label="Homepage" class="header__logo">
        <LogoHeader />
      </a>
      <ButtonRound colour="blue" size="medium" :text="ctaText" :url="ctaLink" class="ml-auto" />
      <button class="ml-20 xls:hidden" :aria-label="ariaLabel" @click="toggleNav()">
        <component :is="menuIcon" class="w-24 h-24" />
      </button>
      <nav
        class="header__nav xls:translate-x-0 xls:static xls:flex"
        :class="{
          'translate-x-full': !navActive,
          'tw-hidden': !navVisible,
          'absolute': navVisible
        }"
        @transitionend="hideNavOnTransition()"
      >
        <NavLink v-for="page in pages" :key="page.id" :page="page" />
        <form id="site-search" action="/search/" class="header__search">
          <input type="text" name="search" placeholder="Search" class="header__search-input" />
          <button>
            <IconSearch class="w-20 mb-4 text-blue-dark flex-shrink-0" />
          </button>
        </form>
      </nav>
    </div>
  </header>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';
import NavLink from '@/components/navigation/NavLink.vue';
import IconMenu from '@/components/svg/IconMenu.vue';
import IconMenuClose from '@/components/svg/IconMenuClose.vue';
import IconSearch from '@/components/svg/IconSearch.vue';
import IconUser from '@/components/svg/IconUser.vue';
import LogoHeader from '@/components/svg/LogoHeader.vue';
import IconAccessibility from '@/components/svg/IconAccessibility.vue';

export default {
  name: 'SiteHeader',
  components: {
    ButtonRound,
    NavLink,
    IconSearch,
    IconUser,
    LogoHeader,
    IconAccessibility
  },
  props: {
    ctaLink: {
      type: String,
      required: true
    },
    ctaText: {
      type: String,
      required: true
    },
    pages: {
      type: Array,
      required: true
    }
  },
  computed: {
    ariaLabel() {
      return this.navActive ? 'Close Menu' : 'Show Menu';
    },
    menuIcon() {
      return this.navActive ? IconMenuClose : IconMenu;
    }
  },
  data() {
    return {
      navActive: false,
      navVisible: false
    };
  },
  methods: {
    hideNavOnTransition() {
      if (!this.navActive) {
        // Only hide on transition out
        this.navVisible = false;
      }
    },
    toggleNav() {
      if (!this.navVisible) {
        this.navVisible = true;
        setTimeout(() => {
          this.navActive = true;
        }, 10);
      } else {
        this.navActive = false; // Wait for transition end
      }
    }
  }
};
</script>

<style>
.header {
  @apply bg-white sticky border-b border-gray-300 z-20;
  top: -0.0625rem;
}

.header__logo {
  width: 6.6875rem;

  @screen xls {
    order: -1;
    width: 10.625rem;
  }
}

.header__nav {
  @apply inset-x-0 pt-10 pb-30 px-20 bg-white shadow-menu flex flex-col order-1;
  @apply transform transition-transform duration-500 ease-in-out;
  margin-top: 1px;
  top: 100%;

  @screen md {
    @apply px-24;
  }

  @screen xls {
    @apply flex-row items-end py-0 shadow-none;
    order: -1;

    & > *:not(:first-child) {
      @apply ml-30;
    }
  }
}

.header__search {
  @apply border border-gray-600 text-blue-dark;
  @apply rounded-24 pt-10 pb-12 px-20 flex;

  @screen xls {
    @apply border-t-0 border-l-0 border-r-0 rounded-0 px-0 border-transparent py-0;
    @apply transition-all duration-300 ease-out relative;
    height: 1.75rem;

    &:hover,
    &:focus-within {
      @apply border-gray-400;
    }

    &:hover {
      & .header__search-input {
        @apply opacity-100 pr-10;
        width: 7.875rem;
      }
    }
  }
}

.header__search-input {
  @apply font-gotham font-medium leading-none w-full pr-10;

  @screen xls {
    @apply flex-shrink w-0 transition-all duration-300 ease-out opacity-0 pr-0;

    &:focus {
      @apply opacity-100 pr-10 outline-none bg-gray-200;
      width: 7.875rem;
    }
  }
}

.header__login-link {
  @apply flex items-center font-gotham text-12 leading-none text-white;

  &:focus,
  &:hover {
    @apply text-gray-500;

    & span:first-child {
      @apply border-gray-500;
    }

    & svg {
      @apply text-gray-500;
    }
  }
}

.icon__accessibility {
  display: none;
}

@media (min-width: 768px) {
  .icon__accessibility {
    display: inline;
  }
}
</style>
