<template>
  <div class="flex justify-center lg:justify-end">
    <button
      v-scroll-to="'#app'"
      class="font-gotham font-medium text-gray-500 text-12 leading-none flex"
    >
      <IconArrow class="w-14 transform rotate-180" />
      <span class="ml-8">BACK TO TOP</span>
    </button>
  </div>
</template>

<script>
import IconArrow from '@/components/svg/IconArrow.vue';

export default {
  name: 'BackToTop',
  components: { IconArrow }
};
</script>
