<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M40.811 25.375a2.662 2.662 0 100-5.324h-6.149V13.9a2.662 2.662 0 00-5.324 0v6.15h-6.15a2.662 2.662 0 100 5.324h6.15v6.15a2.662 2.662 0 005.324 0v-6.15z"
    />
    <path
      d="M50.939 40.804h-3.166A23.748 23.748 0 0038.034.087a2.662 2.662 0 00-1.35 5.15 18.426 18.426 0 01-.003 35.639 2.647 2.647 0 00-1.765 1.537l-.012.026a2.646 2.646 0 00-.137.44c-.006.025-.013.048-.018.073a2.617 2.617 0 00-.043.422c0 .031-.009.06-.009.092 0 .012.004.023.004.036a2.671 2.671 0 00.033.325c.01.08.02.161.037.24.005.02.004.039.01.058.018.071.048.136.072.204.024.072.045.145.075.213.023.051.054.097.08.146a2.655 2.655 0 00.716.864l.024.02a2.653 2.653 0 00.351.225l.007.004a2.632 2.632 0 001.253.327h13.58a5.288 5.288 0 015.281 5.282v9.928a2.662 2.662 0 005.324 0V51.41A10.618 10.618 0 0050.94 40.804zM29.3 43.502c0-.013.003-.024.003-.036 0-.032-.008-.06-.01-.092a2.617 2.617 0 00-.042-.422c-.005-.025-.012-.048-.018-.073a2.653 2.653 0 00-.136-.44l-.013-.027a2.647 2.647 0 00-1.765-1.536 18.426 18.426 0 01-.004-35.639 2.662 2.662 0 10-1.35-5.15 23.748 23.748 0 00-9.738 40.717H13.06A10.617 10.617 0 002.456 51.41v9.928a2.662 2.662 0 005.324 0V51.41a5.287 5.287 0 015.28-5.282h13.58a2.632 2.632 0 001.254-.327l.007-.004a2.631 2.631 0 00.35-.225l.025-.02a2.656 2.656 0 00.717-.865c.026-.049.056-.094.079-.144.03-.07.05-.143.076-.215.023-.068.053-.132.072-.203.005-.02.004-.039.009-.058.018-.078.026-.16.037-.24a2.671 2.671 0 00.033-.325z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMentalHealth'
};
</script>
