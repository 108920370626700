<template>
  <svg
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M32.5 43c1.381 0 2.5 1.133 2.5 2.53v15.94c0 1.396-1.119 2.53-2.5 2.53S30 62.866 30 61.47V45.53c0-1.397 1.119-2.53 2.5-2.53zm-7.271-4.274a2.378 2.378 0 010 3.506L13.497 53.274a2.714 2.714 0 01-1.863.726 2.714 2.714 0 01-1.862-.726 2.377 2.377 0 010-3.507l11.73-11.041c1.03-.968 2.698-.968 3.727 0zm17.268 0l11.73 11.041a2.377 2.377 0 010 3.507 2.714 2.714 0 01-1.861.726 2.714 2.714 0 01-1.863-.726L38.771 42.232a2.378 2.378 0 010-3.506c1.029-.968 2.697-.968 3.726 0zM61.47 29c1.397 0 2.53 1.12 2.53 2.5S62.867 34 61.47 34H45.53C44.134 34 43 32.88 43 31.5s1.134-2.5 2.53-2.5h15.94zm-43 0c1.397 0 2.53 1.12 2.53 2.5S19.867 34 18.47 34H2.53C1.134 34 0 32.88 0 31.5S1.134 29 2.53 29h15.94zm-4.973-17.274l11.73 11.041a2.377 2.377 0 010 3.507 2.71 2.71 0 01-1.861.726 2.707 2.707 0 01-1.863-.726L9.771 15.232a2.378 2.378 0 010-3.506c1.029-.968 2.697-.968 3.726 0zm40.732 0a2.378 2.378 0 010 3.506L42.497 26.274a2.71 2.71 0 01-1.862.726 2.714 2.714 0 01-1.864-.726 2.378 2.378 0 010-3.507l11.732-11.041c1.028-.968 2.697-.968 3.726 0zM32.5 0C33.881 0 35 1.133 35 2.53v15.94c0 1.396-1.119 2.53-2.5 2.53S30 19.866 30 18.47V2.53C30 1.133 31.119 0 32.5 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconStar'
};
</script>
