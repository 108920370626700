<template>
  <div class="call-to-action">
    <div class="container py-40 lg:py-60 xl:py-80">
      <div class="column-row-group flex-col lg:flex-row">
        <div class="column-row lg:w-5/12 lg:flex lg:items-end mb-15 lg:mb-0">
          <h2 class="heading--headline">{{ heading }}</h2>
        </div>
        <div class="column-row lg:w-5/12 lg:flex lg:items-end mb-20 lg:mb-0">
          <p>{{ body }}</p>
        </div>
        <div class="column-row lg:w-2/12 lg:flex lg:items-end">
          <ButtonRound
            colour="lime"
            size="xlarge"
            :text="buttonText"
            :url="buttonLink"
            class="w-8/12 lg:w-full lg:text-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'CallToAction',
  components: { ButtonRound },
  props: {
    heading: String,
    body: String,
    buttonText: String,
    buttonLink: String
  }
};
</script>

<style>
.call-to-action {
  @apply bg-blue-dark text-white text-center rounded-30 max-w-content;

  @screen lg {
    @apply text-left;
  }

  @screen xl {
    @apply rounded-60 mx-auto;
  }
}
</style>
