<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M37.364 63.511a2.618 2.618 0 01-.472-5.192 26.77 26.77 0 000-52.637 2.618 2.618 0 11.95-5.149 32.006 32.006 0 010 62.934 2.638 2.638 0 01-.478.044zM26.665 63.517a2.608 2.608 0 01-.476-.043 32.006 32.006 0 010-62.947 2.618 2.618 0 01.945 5.15 26.771 26.771 0 000 52.647 2.618 2.618 0 01-.469 5.193z"
    />
    <path
      d="M32.009 13.522a2.2 2.2 0 012.214 2.214v.596a17.898 17.898 0 016.174 2.044 2.869 2.869 0 01-1.363 5.407 3.104 3.104 0 01-1.49-.383 16.309 16.309 0 00-3.534-1.448v6.472c6.472 1.745 9.24 4.385 9.24 9.154s-3.662 7.834-9.027 8.43v2.256a2.214 2.214 0 01-4.428 0v-2.299a21.288 21.288 0 01-8.388-2.98 2.925 2.925 0 011.49-5.45 3.094 3.094 0 011.661.554 16.254 16.254 0 005.45 2.384v-6.77c-6.131-1.66-9.154-4.002-9.154-9.069 0-4.683 3.576-7.833 8.941-8.387v-.511a2.199 2.199 0 012.214-2.214zm-2.001 13.795V21.57c-2.044.298-2.938 1.32-2.938 2.682 0 1.32.596 2.214 2.938 3.066zm4.002 7.45v5.919c2.001-.298 3.023-1.235 3.023-2.767 0-1.406-.724-2.342-3.023-3.151z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMoney'
};
</script>
