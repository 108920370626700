<template>
  <main>
    <div class="container pt-40 lg:pt-50 xl:pt-60">
      <h1 class="heading--headline text-gray-600 mb-15 lg:mb-20">{{ title }}</h1>
      <div class="column-row-group justify-between mb-30 lg:mb-40">
        <div class="column-row mb-20 lg:mb-40 lg:w-8/12 xl:w-6/12" v-html="intro"></div>
        <div class="column-row flex items-end mb-20 lg:mb-40 lg:w-4/12">
          <form
            class="text-blue-dark border border-gray-500 rounded-24 pt-10 pb-12 px-20 flex w-full"
            @submit.prevent="updateSearch()"
          >
            <input
              v-model="search"
              type="text"
              name="search"
              placeholder="Search..."
              class="leading-none w-full focus:outline-none focus:bg-gray-300"
            />
            <button><IconSearch class="w-16" @click="updateSearch()" /></button>
          </form>
        </div>
        <div
          class="column-row flex flex-col justify-end lg:w-9/12 xl:w-6/12 mb-20 lg:mb-30 xl:mb-0"
        >
          <h2 class="font-walsheim font-bold mb-10">Quick Filters</h2>
          <div class="column-row-group -mx-4 lg:-mx-8 -my-6">
            <div class="column-row w-auto px-4 lg:px-8 py-6">
              <ButtonRound colour="blue" size="medium" text="All" @click.native="resetFilters()" />
            </div>
            <div
              v-for="(quickFilter, index) in quickFilters"
              :key="index"
              class="column-row w-auto px-4 lg:px-8 py-6"
            >
              <ButtonRound
                colour="white"
                size="medium"
                :text="quickFilter.label"
                @click.native="getQuery(quickFilter.query)"
              />
            </div>
          </div>
        </div>
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <label for="topic-filters" class="option-text mb-6 lg:mb-10">Topic</label>
          <div class="relative w-full">
            <select
              name="topic"
              id="topic-filters"
              class="select-filter option-text w-full"
              v-model="selectedTopic"
              @change="updateUrl()"
            >
              <option value="">---</option>
              <option v-for="topic in topicFilters" :key="topic.id" :value="topic.slug">
                {{ topic.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0 lg:mr-auto"
        >
          <label for="location-filters" class="option-text mb-6 lg:mb-10">Location</label>
          <div class="relative w-full">
            <select
              name="location"
              id="location-filters"
              class="select-filter option-text w-full"
              v-model="selectedLocation"
              @change="updateUrl()"
            >
              <option value="">---</option>
              <option v-for="location in locationFilters" :key="location.id" :value="location.slug">
                {{ location.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-t-30 xl:rounded-t-60 bg-gray-200 pt-30 lg:pt-40 pb-40 lg:pb-80">
      <div class="container">
        <GrantComponent
          v-for="grant in filteredGrants"
          :key="grant.id"
          :detail-description="grant.detail_description"
          :end-date="grant.end_date"
          :location="grant.location"
          :override-text="grant.override_text"
          :short-description="grant.short_description"
          :start-date="grant.start_date"
          :title="grant.title"
          :topic="grant.topic"
          :url="grant.grant_link"
          class="mb-30 md:mb-40"
        />
        <div v-if="loading" class="flex justify-center">
          <ButtonRound colour="white" size="large" text="Loading..." :disabled="loading" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
// import BackToTop from '@/components/global/BackToTop.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import GrantComponent from '@/components/grants/GrantComponent.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import IconSearch from '@/components/svg/IconSearch.vue';

export default {
  name: 'GrantsCalendar',
  components: {
    // BackToTop,
    ButtonRound,
    GrantComponent,
    IconArrow,
    IconSearch
  },
  props: {
    intro: String,
    quickFilters: Array,
    locationFilters: {
      type: Array,
      required: true
    },
    title: String,
    topicFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentSearch: '',
      firstLoad: false,
      grants: [],
      loading: true,
      search: '',
      selectedLocation: '',
      selectedTopic: ''
    };
  },
  computed: {
    filteredGrants() {
      let results = [...this.grants];
      if (this.currentSearch) {
        results = results.filter(grant => grant.title.includes(this.currentSearch));
      }
      if (this.selectedTopic) {
        results = results.filter(grant =>
          grant.topic ? grant.topic.slug === this.selectedTopic : false
        );
      }
      if (this.selectedLocation) {
        results = results.filter(grant =>
          grant.location ? grant.location.slug === this.selectedLocation : false
        );
      }
      return results;
    }
  },
  created() {
    this.getGrants();
    this.getQuery();
  },
  methods: {
    getGrants() {
      axios
        .get(`${process.env.VUE_APP_API_DOMAIN}grants/`)
        .then(response => {
          this.grants = response.data.items;
          this.loading = false;
        })
        .catch(() => {
          console.error('Failed to fetch grants.');
        });
    },
    /**
     * Update filters from query. If query not provided, uses browser URL
     */
    getQuery(query) {
      let params;
      if (query) {
        params = new URLSearchParams(query);
      } else {
        params = new URLSearchParams(window.location.search);
      }

      this.currentSearch = params.get('search') || '';
      this.search = params.get('search') || '';
      this.selectedLocation = params.get('location') || '';
      this.selectedTopic = params.get('topic') || '';

      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.updateUrl();
      }
    },
    resetFilters() {
      this.selectedLocation = '';
      this.selectedTopic = '';
      this.updateUrl();
    },
    updateSearch() {
      this.currentSearch = this.search;
      this.updateUrl();
    },
    updateUrl() {
      const params = new URLSearchParams();
      if (this.currentSearch) params.set('search', this.currentSearch);
      if (this.selectedLocation) params.set('location', this.selectedLocation);
      if (this.selectedTopic) params.set('topic', this.selectedTopic);
      const queryString = params.toString();

      if (queryString) {
        window.history.replaceState({}, '', `?${queryString}`);
      } else {
        window.history.replaceState({}, '', window.location.pathname);
      }
    }
  }
};
</script>
