<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <g>
        <path
          d="m12.5,0C5.6,0,0,5.6,0,12.5s5.6,12.5,12.5,12.5,12.5-5.6,12.5-12.5S19.4,0,12.5,0Zm2.67,20.16l-3.95-5.28-4.89,5.28h-2.69l6.38-6.89L3.82,4.99h5.75l3.62,4.84,4.48-4.84h2.69l-5.97,6.45,6.52,8.72h-5.75Z"
        />
        <polygon points="6.77 6.47 15.91 18.68 17.98 18.68 8.84 6.47 6.77 6.47" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconX'
};
</script>
