<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M7.825 64h48.312a2.688 2.688 0 000-5.376H7.825a2.688 2.688 0 000 5.376zM27.71 28.248a12.093 12.093 0 010-23.003A2.688 2.688 0 1026.053.132a17.486 17.486 0 00-8.225 27.575l.33.41H16.56A11.48 11.48 0 005.094 39.584v9.874a2.688 2.688 0 005.375 0v-9.874a6.098 6.098 0 016.092-6.092h10.32a2.688 2.688 0 00.829-5.244zM36.29 28.248a12.093 12.093 0 000-23.003A2.688 2.688 0 1137.947.132a17.486 17.486 0 018.225 27.575l-.33.41h1.597a11.48 11.48 0 0111.467 11.467v9.874a2.688 2.688 0 01-5.375 0v-9.874a6.098 6.098 0 00-6.092-6.092H37.12a2.688 2.688 0 01-.829-5.244z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPerson'
};
</script>
