<template>
  <div class="container">
    <div class="column-row-group flex-col-reverse lg:flex-row lg:justify-between">
      <div
        class="column-row lg:w-5/12 text-center lg:text-left flex flex-col justify-center items-center lg:items-start"
      >
        <h1 class="heading--headline text-gray-600 mb-10 lg:mb-20">{{ title }}</h1>
        <p class="text-18 text-gray-600 w-10/12 lg:w-full">{{ intro }}</p>
        <ButtonRound
          v-if="buttonLink && buttonText"
          colour="lime"
          size="xlarge"
          :text="buttonText"
          :url="buttonLink"
          class="mt-20 w-8/12 md:w-4/12 lg:w-2/5"
        />
      </div>
      <div class="column-row lg:w-6/12 mb-30 lg:mb-0">
        <div class="paragraph-header__shape header-image--height" :class="shapeColour">
          <img
            v-if="image.src"
            v-bind="image"
            sizes="(max-width: 1023px) 100vw, 50vw"
            class="header-image--height w-full object-cover"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'ParagraphHeader',
  components: { ButtonRound },
  props: {
    backgroundColour: {
      type: String,
      default: 'blue'
    },
    buttonLink: String,
    buttonText: String,
    image: Object,
    intro: String,
    title: String
  },
  computed: {
    shapeColour() {
      return `bg-${this.backgroundColour}`;
    }
  }
};
</script>

<style>
.paragraph-header__shape {
  @apply -mr-20 overflow-hidden;
  border-radius: 15rem 0 0 15rem;

  @screen md {
    @apply -mr-24;
  }

  @screen lg {
    @apply -ml-12 mr-0;
    width: 50vw;
  }
}
</style>
