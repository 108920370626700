<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M9.414 8l6.293-6.293A.999.999 0 1014.293.293L8 6.586 1.707.293A.999.999 0 10.293 1.707L6.586 8 .293 14.293a.999.999 0 101.414 1.414L8 9.414l6.293 6.293a.997.997 0 001.414 0 .999.999 0 000-1.414L9.414 8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCross'
};
</script>
