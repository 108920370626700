<template>
  <svg
    viewBox="0 0 47 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M52.657 50.667c1.846 0 3.343 1.487 3.343 3.322v6.69C56 62.512 54.503 64 52.657 64H12.343C10.497 64 9 62.513 9 60.678v-6.69c0-1.834 1.497-3.321 3.343-3.321 1.846 0 3.344 1.487 3.344 3.322v3.367h33.626V53.99c0-1.835 1.498-3.322 3.344-3.322zm-4.383-19.1a3.142 3.142 0 01.082 4.541L34.84 49.686a3.367 3.367 0 01-2.383.98h-.005a3.362 3.362 0 01-2.384-.988L16.636 36.101a3.142 3.142 0 01.097-4.54 3.359 3.359 0 012.292-.894c.871 0 1.74.33 2.391.988l11.049 11.17 11.127-11.178c1.27-1.275 3.367-1.312 4.682-.08zM32.5 0c1.854 0 3.357 1.433 3.357 3.2v25.6c0 1.767-1.503 3.2-3.357 3.2-1.854 0-3.357-1.433-3.357-3.2V3.2c0-1.767 1.503-3.2 3.357-3.2z"
      transform="translate(-9)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDownload'
};
</script>
