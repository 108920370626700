<template>
  <div class="storytellingblock lg:flex" @mouseover="stopAutoPlay" @mouseout="autoPlay">
    <div class="storytellingblock__nav lg:w-1/2">
      <ButtonRound
        colour="blue"
        class="storytellingblock__nav-button -top-2 -translate-y-1/2 rotate-180"
        :disabled="isTransitioning"
        aria-label="Previous"
        @click.native="moveToPrevStory"
      >
        <IconArrow class="w-12 mt-2 flex-shrink-0" />
      </ButtonRound>
      <div class="storytellingblock__nav-list">
        <ul ref="navContainer" role="tablist">
          <li
            v-for="(story, idx) in storiesList"
            :key="idx"
            :class="[
              { 'active subheading': isActiveId(idx) },
              { transitioning: isTransitioning },
              storyFadeClass(idx)
            ]"
            :style="navItemStyle(idx)"
            @click="moveToStory(idx)"
            ref="navItems"
            role="tab"
            :aria-selected="isActiveId(idx) ? 'true' : 'false'"
            :aria-controls="`story-tabpanel-${idx}`"
            :id="`story-control-${idx}`"
          >
            <span>I'd like to...</span>
            {{ story.heading }}
          </li>
        </ul>
      </div>
      <ButtonRound
        colour="blue"
        class="storytellingblock__nav-button -bottom-2 translate-y-1/2"
        :disabled="isTransitioning"
        aria-label="Next"
        @click.native="moveToNextStory"
      >
        <IconArrow class="w-12 mt-2 flex-shrink-0" />
      </ButtonRound>
    </div>
    <transition-group name="fade" tag="div" class="storytellingblock__stories">
      <article
        v-for="article in storiesList"
        :key="article.key"
        class="storytellingblock__story"
        :class="{ relative: isActiveId(article.key) }"
        v-show="isActiveId(article.key)"
        role="tabpanel"
        :id="`story-tabpanel-${article.key}`"
        :aria-labelledby="`story-control-${article.key}`"
      >
        <a
          :href="article.link"
          class="storytellingblock__story-icon"
          :class="`bg-${article.icon_colour}`"
        >
          <IconComponent colour="white" :icon="article.icon_type" />
        </a>
        <p class="mb-20 w-11/12 lg:w-5/6">{{ article.description }}</p>
        <ButtonRound
          colour="blue"
          size="medium"
          :text="article.button_label"
          :url="article.link"
          class="mb-20 lg:mb-0"
        />
      </article>
    </transition-group>
  </div>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import IconComponent from '@/components/global/IconComponent.vue';

export default {
  name: 'StoryTellingBlock',
  props: {
    stories: Array
  },
  components: {
    ButtonRound,
    IconArrow,
    IconComponent
  },
  data() {
    return {
      activeId: 0,
      activeHeight: 0,
      navItemPositions: [],
      autoPlayDelay: 50000,
      itemHeight: 0,
      isTransitioning: false,
      storiesList: [],
      lastId: 0
    };
  },
  computed: {
    storiesListCount() {
      return this.storiesList.length;
    }
  },
  mounted() {
    this.setupNav();
    this.autoPlay();
    window.addEventListener('resize', this.getHeights);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeights);
    this.stopAutoPlay();
  },
  methods: {
    autoPlay() {
      this.intervalId = setInterval(() => {
        this.moveToNextStory();
      }, this.autoPlayDelay);
    },
    stopAutoPlay() {
      clearInterval(this.intervalId);
    },
    isActiveId(idx) {
      return this.activeId === idx;
    },
    getHeights() {
      this.itemHeight = this.$refs.navItems[2].clientHeight;
      this.activeHeight = this.$refs.navItems[this.activeId].clientHeight;
    },
    getNextId(id) {
      return id === this.lastId ? 0 : id + 1;
    },
    getPrevId(id) {
      return id === 0 ? this.lastId : id - 1;
    },
    navItemStyle(idx) {
      const transform = this.navItemPositions[idx];
      const offset = this.isActiveId(idx) ? this.activeHeight / 2 : this.itemHeight / 2;
      return `top: calc(50% - ${offset}px); transform: translateY(${transform}px);`;
    },
    moveToNextStory() {
      this.navItemPositions.unshift(this.navItemPositions.pop());
      // delay so that 'I'd like to' doesnt appear its jumping
      this.wait(100).then(() => {
        this.activeId = this.getNextId(this.activeId);
      });
    },
    moveToPrevStory() {
      this.navItemPositions.push(this.navItemPositions.shift());
      // delay so that 'I'd like to' doesnt appear its jumping
      this.wait(100).then(() => {
        this.activeId = this.getPrevId(this.activeId);
      });
    },
    moveToPrevTwice() {
      this.isTransitioning = true;
      this.moveToPrevStory();
      this.wait(100)
        .then(() => {
          this.moveToPrevStory();
        })
        .then(() => {
          this.isTransitioning = false;
        });
    },
    moveToNextTwice() {
      this.isTransitioning = true;
      this.moveToNextStory();
      this.wait(100)
        .then(() => {
          this.moveToNextStory();
        })
        .then(() => {
          this.isTransitioning = false;
        });
    },
    moveToStory(id) {
      const isPrev = id === this.getPrevId(this.activeId);
      const isNext = id === this.getNextId(this.activeId);
      const isPrev2 = id === this.getPrevId(this.getPrevId(this.activeId));
      if (this.isActiveId(id)) return null;
      if (isPrev) return this.moveToPrevStory();
      if (isNext) return this.moveToNextStory();
      if (isPrev2) return this.moveToPrevTwice();
      return this.moveToNextTwice();
    },
    wait(time) {
      return new Promise(resolve => {
        setTimeout(resolve, time);
      });
    },
    setInitialPositions() {
      const firstHalf = Math.ceil(this.storiesListCount / 2);
      this.navItemPositions = this.storiesList.map((story, idx) => {
        const isPrev = idx === this.getNextId(this.activeId);
        const isNext = idx === this.getPrevId(this.activeId);
        const height =
          isPrev || isNext ? this.activeHeight / 2 + this.itemHeight / 2 : this.itemHeight;
        return idx <= firstHalf ? idx * height : height * (idx - this.storiesListCount);
      });
    },
    setupNav() {
      this.storiesList =
        this.stories.length < 10 ? [...this.stories, ...this.stories] : this.stories;

      this.storiesList = this.storiesList.map((item, idx) => {
        const newItem = { ...item };
        newItem.key = idx;
        return newItem;
      });

      this.lastId = this.storiesList.length - 1;
      this.wait().then(this.getHeights).then(this.setInitialPositions);
    },
    storyFadeClass(id) {
      const nextId = this.getNextId(this.activeId);
      const prevId = this.getPrevId(this.activeId);
      if (id === nextId || id === prevId || id === this.activeId) {
        return 'opacity-60';
      }
      if (id === this.getNextId(nextId) || id === this.getPrevId(prevId)) {
        return 'opacity-20';
      }
      return 'opacity-0';
    }
  }
};
</script>

<style>
.storytellingblock {
  @apply my-40 -mx-20;

  @screen md {
    @apply -mx-24;
  }

  @screen lg {
    @apply mx-auto;
  }

  @screen xl {
    @apply my-60;
  }
}

.storytellingblock__nav {
  @apply rounded-20 border-4 border-gray-200 relative bg-gray-200 py-16;
  @apply flex flex-col justify-center items-center z-10;
}

.storytellingblock__nav-button {
  @apply absolute transform;
  height: 2rem;
  width: 2rem;
}

.storytellingblock__nav-list {
  @apply my-16 w-full overflow-hidden;
  height: 14.625rem;

  @screen md {
    @apply my-30;
    height: 14.5rem;
  }

  @screen lg {
    @apply my-40;
    height: 15rem;
  }

  & ul {
    @apply relative transition-transform duration-300 h-full;
  }

  & li {
    @apply py-8 text-center font-walsheim text-18 text-blue-dark cursor-pointer;
    @apply w-full relative ease-out absolute top-1/2;
    @apply duration-300 transition-all;

    @screen md {
      @apply py-10;
    }

    @screen lg {
      @apply text-20;
    }

    &.transitioning {
      pointer-events: none;
    }

    &.active {
      @apply opacity-100;

      & span {
        @apply block;

        @screen md {
          @apply inline;
        }
      }
    }

    & span {
      @apply hidden;
    }
  }
}

.fade-leave-active {
  transition: all 300ms ease-in;
}
.fade-enter-active {
  transition: all 300ms ease-in 300ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.storytellingblock__stories {
  @apply rounded-b-20 border-4 border-gray-200 -mt-20 relative overflow-hidden;
  min-height: 20.75rem;

  @screen lg {
    @apply rounded-b-0 rounded-r-20 mt-0 -ml-20;
    width: calc(50% + 1.25rem);
  }
}

.storytellingblock__story {
  @apply flex flex-col items-center pt-40 pb-20 absolute w-full h-full;
  @apply text-center top-0 left-0 px-20;

  @screen md {
    @apply px-24;
  }
  @screen lg {
    @apply pl-16 px-0;
  }
}

.storytellingblock__story-icon {
  @apply rounded-full flex justify-center items-center mt-16 mb-20 flex-shrink-0;
  width: 4.5rem;
  height: 4.5rem;

  @screen lg {
    @apply mt-0;
  }

  & svg {
    width: 2rem;
  }
}
</style>
