<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M26.708 58.704a13.284 13.284 0 01-13.269-13.27V18.523a2.648 2.648 0 10-5.296 0v26.913A18.586 18.586 0 0026.708 64a2.648 2.648 0 100-5.296zM53.209 24.536a2.648 2.648 0 00-2.648 2.648v18.25a13.284 13.284 0 01-13.27 13.27 2.648 2.648 0 000 5.296 18.586 18.586 0 0018.566-18.565V27.184a2.648 2.648 0 00-2.648-2.648zM34.648 2.648a2.648 2.648 0 10-5.296 0v27.367a2.648 2.648 0 105.296 0z"
    />
    <path
      d="M21.396 34.335a2.648 2.648 0 002.648-2.648V8.223a2.648 2.648 0 00-5.296 0v23.464a2.648 2.648 0 002.648 2.648zM42.604 38.237a2.648 2.648 0 002.648-2.647V10.452a2.648 2.648 0 10-5.296 0V35.59a2.648 2.648 0 002.648 2.647z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHand'
};
</script>
