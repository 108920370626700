<template>
  <section>
    <div class="tabbed-section__header">
      <div class="container">
        <h2 class="heading--page mb-20">{{ heading }}</h2>
        <p class="text-center lg:text-left lg:w-6/12">{{ description }}</p>
      </div>
    </div>
    <div class="tabbed-section__tabs-container">
      <ul
        v-scroll-spy-active="{ class: 'scrollspy-active' }"
        v-scroll-spy-link
        class="container flex"
      >
        <li
          v-for="(label, index) in labels"
          :key="index"
          class="py-30 rounded-t-30 flex justify-center cursor-pointer"
          :class="tabWidth"
        >
          <a>{{ label }}</a>
        </li>
      </ul>
    </div>
    <div v-scroll-spy="{ offset: 220 }">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TabbedSection',
  props: {
    heading: String,
    description: String,
    labels: Array // of Strings
  },
  computed: {
    tabWidth() {
      return `w-1/${this.labels.length}`;
    }
  }
};
</script>

<style>
.tabbed-section__header {
  @apply bg-gray-200 rounded-t-30 pt-40 -mb-50 text-center max-w-content;
  padding-bottom: 5.625rem;

  @screen lg {
    @apply text-left pb-20 mb-0;
  }

  @screen xl {
    @apply rounded-t-60 mx-auto;
  }
}

.tabbed-section__tabs-container {
  @apply hidden sticky bg-gray-200 max-w-content rounded-b-30 pt-20;
  @apply font-gotham text-blue-dark font-bold z-10;
  top: 7.1875rem;

  @screen lg {
    @apply block;
  }

  @screen xls {
    @apply rounded-b-60 mx-auto;
    top: 9.6875rem;
  }
}

.scrollspy-active {
  @apply bg-white;
}
</style>
