<template>
  <div class="max-w-content mx-auto">
    <iframe
      allow="geolocation"
      src="https://portal.goodsports.com.au/map/embed/"
      title="ADF Community Map"
      width="100%"
      class="adf-community-map"
      id="adf-community-map"
    ></iframe>
  </div>
</template>

<script>
import { sendGaEvent } from '@/utils';

export default {
  name: 'ClubMap',
  mounted() {
    window.addEventListener('message', this.messageHandler);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler);
  },
  methods: {
    messageHandler(message) {
      if (message.data.event) {
        // eslint-disable-next-line object-curly-newline
        const { event, eventCategory, eventAction, eventLabel } = message.data;
        sendGaEvent(event, eventCategory, eventAction, eventLabel);
      }
    }
  }
};
</script>

<style>
.adf-community-map {
  @apply border-none;
  min-height: 787px;

  @screen md {
    min-height: 674px;
  }

  @media (min-width: 1366px) {
    min-height: 770px;
  }

  /* @screen xl {
    min-height: 770px;
  } */
}
</style>
