<template>
  <svg
    viewBox="0 0 53 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M56.12 58c1.59 0 2.88 1.343 2.88 3s-1.29 3-2.88 3H9.88C8.29 64 7 62.657 7 61s1.29-3 2.88-3h46.24zM30.856 2.01c.5 1.525-.347 3.161-1.892 3.656-4.9 1.566-8.19 6.035-8.19 11.122 0 5.088 3.29 9.558 8.19 11.124a2.9 2.9 0 012 3.215c-.227 1.411-1.459 2.45-2.907 2.45H17.728c-3.222 0-5.844 2.587-5.844 5.768v9.752C11.884 50.7 10.566 52 8.942 52 7.317 52 6 50.7 6 49.097v-9.752c0-6.382 5.261-11.575 11.728-11.575H18.8a17.327 17.327 0 01-3.91-10.98c0-7.614 4.927-14.304 12.26-16.648 1.546-.494 3.206.341 3.706 1.867zM37.841.141c7.17 2.296 12.034 8.763 12.23 16.181h2.09c.544 0 .987-.43.987-.96v-3.525c0-1.567 1.31-2.838 2.926-2.838S59 10.27 59 11.838v3.526C59 19.023 55.931 22 52.16 22l-2.88.001a17.368 17.368 0 01-3.105 5.769h1.121C53.75 27.77 59 32.963 59 39.345v9.752C59 50.7 57.685 52 56.064 52c-1.62 0-2.936-1.3-2.936-2.903v-9.752c0-3.181-2.616-5.768-5.832-5.768h-10.36a2.926 2.926 0 01-2.9-2.45 2.9 2.9 0 011.996-3.215A11.75 11.75 0 0042.974 22H32.925C31.31 22 30 20.73 30 19.162s1.31-2.838 2.925-2.838h11.27c-.193-4.893-3.425-9.14-8.163-10.658-1.542-.495-2.388-2.131-1.888-3.657.5-1.526 2.155-2.36 3.697-1.867zM9 9c1.657 0 3 1.27 3 2.838v7.324C12 20.729 10.657 22 9 22s-3-1.27-3-2.838v-7.324C6 10.271 7.343 9 9 9z"
      transform="translate(-6)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconVolunteer'
};
</script>
