<template>
  <component v-bind="componentProps" class="button-round" :class="[buttonSize, buttonColour]">
    {{ text }}
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ButtonRound',
  props: {
    colour: {
      type: String,
      default: 'white',
      validator(value) {
        return ['white', 'blue', 'lime'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'xlarge'].includes(value);
      }
    },
    text: String,
    url: String
  },
  computed: {
    buttonColour() {
      switch (this.colour) {
        case 'blue':
          return 'button--blue';
        case 'lime':
          return 'button--lime';
        default:
          return 'button--white';
      }
    },
    buttonSize() {
      switch (this.size) {
        case 'small':
          return 'button--small';
        case 'large':
          return 'button--large';
        case 'xlarge':
          return 'button--xlarge';
        default:
          return 'button--medium';
      }
    },
    componentProps() {
      if (this.url) {
        return {
          is: 'a',
          href: this.url
        };
      }
      return { is: 'button' };
    }
  }
};
</script>

<style>
.button-round {
  @apply font-gotham font-medium uppercase leading-none border-2 rounded-24 text-center;
  @apply inline-flex justify-center items-center transition-colors duration-150;

  &:focus {
    @apply outline-none;
  }
}

.button--small {
  @apply py-4 px-8 text-12;
}

.button--medium {
  @apply py-6 px-10 text-15;
}

.button--large {
  @apply py-10 px-20 text-17;
}

.button--xlarge {
  @apply pb-12 px-24 text-19;
  padding-top: 0.8125rem;
}

.button--white {
  @apply bg-transparent text-blue-dark border-blue-dark;

  &:focus {
    @apply bg-gray-300 border-gray-300;
  }

  &:hover {
    @apply bg-blue-dark border-blue-dark text-white;
  }
}

.button--blue {
  @apply bg-blue-dark border-blue-dark text-white;

  &:focus {
    @apply bg-gray-300 border-gray-300 text-blue-dark;
  }

  &:hover {
    @apply bg-white text-blue-dark;
  }
}

.button--lime {
  @apply bg-lime border-lime text-blue-dark;

  &:focus {
    @apply bg-white border-blue-dark;
  }

  &:hover {
    @apply bg-lime-dark border-lime-dark text-white;
  }
}
</style>
