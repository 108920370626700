<template>
  <div class="grant-signup">
    <div class="container py-40 lg:py-60 xl:py-80">
      <div class="column-row-group flex-col lg:flex-row">
        <div class="column-row lg:w-5/12 lg:flex lg:items-end mb-15 lg:mb-0">
          <h2 class="heading--headline">{{ heading }}</h2>
        </div>
        <div class="column-row lg:w-7/12 lg:flex lg:flex-col lg:justify-end mb-20 lg:mb-0">
          <p class="mb-20">{{ body }}</p>
          <GrantsSignupForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrantsSignupForm from '@/components/grants/GrantsSignupForm.vue';

export default {
  name: 'GrantsSignup',
  components: { GrantsSignupForm },
  props: {
    heading: String,
    body: String,
    buttonText: String,
    buttonLink: String
  }
};
</script>

<style>
.grant-signup {
  @apply bg-green-dark text-white text-center rounded-30 max-w-content;

  @screen lg {
    @apply text-left;
  }

  @screen xl {
    @apply rounded-60 mx-auto;
  }
}
</style>
