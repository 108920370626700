<template>
  <svg
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M28.956 2.164c.26 1.437-.667 2.818-2.071 3.083C14.304 7.635 5.172 18.885 5.172 32c0 13.116 9.132 24.366 21.713 26.754 1.404.266 2.331 1.647 2.071 3.083-.23 1.273-1.317 2.164-2.54 2.164-.156 0-.315-.015-.474-.045C10.91 61.105 0 47.665 0 32 0 16.336 10.91 2.897 25.942.045c1.404-.267 2.754.682 3.014 2.12zm9.102-2.12C53.09 2.898 64 16.337 64 32c0 15.665-10.91 29.105-25.942 31.956-.16.03-.318.045-.474.045-1.223 0-2.31-.89-2.54-2.164-.26-1.436.667-2.817 2.071-3.083C49.696 56.365 58.828 45.115 58.828 32S49.696 7.635 37.115 5.247c-1.404-.265-2.331-1.646-2.071-3.083.26-1.437 1.609-2.386 3.014-2.12zM27.962 24.003a1 1 0 01.563.177l11.01 7.597a1 1 0 01-.006 1.651l-11.091 7.517a1 1 0 01-1.561-.833l.08-15.114a1 1 0 011.005-.995z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPlay'
};
</script>
