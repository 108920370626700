<template>
  <main>
    <div class="container pt-40 lg:pt-50 xl:pt-60 pb-30 lg:pb-40">
      <h1 class="heading--headline text-gray-600 mb-15 lg:mb-20">{{ title }}</h1>
      <div class="text-gray-600 mb-20 lg:mb-40" v-html="intro"></div>
      <div class="column-row-group">
        <div class="column-row flex flex-col justify-end xl:w-6/12 mb-20 lg:mb-30 xl:mb-0">
          <h2 class="font-walsheim font-bold mb-10">Quick Filters</h2>
          <div class="column-row-group -mx-4 lg:-mx-8 -my-6">
            <div class="column-row w-auto px-4 lg:px-8 py-6">
              <ButtonRound colour="blue" size="medium" text="All" @click.native="resetFilters()" />
            </div>
            <div
              v-for="(quickFilter, index) in quickFilters"
              :key="index"
              class="column-row w-auto px-4 lg:px-8 py-6"
            >
              <ButtonRound
                colour="white"
                size="medium"
                :text="quickFilter.label"
                @click.native="getQuery(quickFilter.query)"
              />
            </div>
          </div>
        </div>
        <!-- <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <h2 class="font-walsheim font-bold mb-14">Filter by:</h2>
          <label for="sport-filters" class="option-text mb-6 lg:mb-10">Sport</label>
          <div class="relative w-full">
            <select
              name="sport"
              id="sport-filters"
              class="select-filter option-text w-full"
              v-model="selectedSport"
              @change="getFirstPage()"
            >
              <option value="">---</option>
              <option v-for="sport in sportFilters" :key="sport.id" :value="sport.slug">
                {{ sport.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div> -->
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <!-- TODO: Remove H2 when sport filters readded -->
          <h2 class="font-walsheim font-bold mb-14">Filter by:</h2>
          <label for="topic-filters" class="option-text mb-6 lg:mb-10">Topic</label>
          <div class="relative w-full">
            <select
              name="topic"
              id="topic-filters"
              class="select-filter option-text w-full"
              v-model="selectedTopic"
              @change="getFirstPage()"
            >
              <option value="">---</option>
              <option v-for="topic in topicFilters" :key="topic.id" :value="topic.slug">
                {{ topic.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
        <div
          class="column-row flex flex-col justify-end items-start md:w-4/12 xl:w-2/12 mb-10 lg:mb-0"
        >
          <label for="location-filters" class="option-text mb-6 lg:mb-10">Location</label>
          <div class="relative w-full">
            <select
              name="location"
              id="location-filters"
              class="select-filter option-text w-full"
              v-model="selectedLocation"
              @change="getFirstPage()"
            >
              <option value="">---</option>
              <option v-for="location in locationFilters" :key="location.id" :value="location.slug">
                {{ location.name }}
              </option>
            </select>
            <IconArrow class="absolute select-arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="rounded-t-30 xl:rounded-t-60 bg-gray-200 pb-40 lg:pb-80 mt-60">
      <div class="container">
        <div class="column-row-group justify-center -mb-30 lg:mb-0 transform -translate-y-60">
          <div
            v-for="featured in featuredArticles"
            :key="featured.id"
            class="column-row mb-30 last:mb-0 md:w-8/12 lg:mb-0 lg:w-6/12"
          >
            <ArticleCard
              :description="featured.intro"
              :imageAlt="featured.card_image ? featured.card_image.alt : ''"
              :imageSrc="featured.card_image ? featured.card_image.url : ''"
              :publishDate="featured.meta.first_published_at"
              :title="featured.title"
              :topic="featured.topic_name"
              :url="featured.url"
            />
          </div>
        </div>
        <div class="column-row-group -my-15 md:-my-20 lg:-my-30">
          <div
            v-for="article in articles"
            :key="article.id"
            class="column-row py-15 md:py-20 lg:py-30 md:w-6/12 lg:w-4/12"
          >
            <ArticleCard
              :description="article.intro"
              :imageAlt="article.card_image ? article.card_image.alt : ''"
              :imageSrc="article.card_image ? article.card_image.url : ''"
              :publishDate="article.meta.first_published_at"
              :title="article.title"
              :topic="article.topic_name"
              :url="article.url"
            />
          </div>
        </div>
        <hr class="border-t border-gray-400 my-30 lg:mt-60 lg:mb-40" />
        <div class="column-row-group lg:justify-end items-end">
          <div class="column-row lg:w-4/12 mb-30 lg:mb-0">
            <ButtonRound
              v-if="next"
              colour="white"
              size="large"
              :text="buttonText"
              class="w-full"
              :disabled="loading"
              @click.native="loadMore()"
            />
          </div>
          <div class="column-row lg:w-4/12">
            <BackToTop />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import ArticleCard from '@/components/cards/ArticleCard.vue';
import BackToTop from '@/components/global/BackToTop.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import IconArrow from '@/components/svg/IconArrow.vue';

const PAGE_COUNT = 18;

export default {
  name: 'ArticleIndexPage',
  components: {
    ArticleCard,
    BackToTop,
    ButtonRound,
    IconArrow
  },
  props: {
    intro: String,
    quickFilters: Array,
    locationFilters: {
      type: Array,
      required: true
    },
    sportFilters: {
      type: Array,
      required: true
    },
    title: String,
    topicFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      articles: [],
      featuredArticles: [],
      firstLoad: true,
      loading: true,
      offset: 0,
      queryString: null,
      selectedLocation: '',
      selectedSport: '',
      selectedTopic: '',
      totalCount: 1
    };
  },
  computed: {
    buttonText() {
      return this.loading ? 'Loading...' : 'Load More';
    },
    next() {
      return this.offset < this.totalCount;
    }
  },
  created() {
    this.getQuery();
  },
  methods: {
    getFirstPage() {
      this.loading = true;
      this.offset = 0;
      this.totalCount = 1;
      this.featuredArticles = [];
      this.articles = [];

      this.updateQueryAndUrl();

      axios
        .get(`${process.env.VUE_APP_API_DOMAIN}articles/?limit=20${this.queryString}`)
        .then(response => {
          this.totalCount = response.data.meta.total_count;
          const pages = response.data.items;
          this.featuredArticles = pages.slice(0, 2);
          this.articles = pages.slice(2);
          this.offset = 20;
          this.loading = false;
        })
        .catch(() => {
          console.error('Failed to fetch articles.');
        });
    },
    /**
     * Update filters from query. If query not provided, uses browser URL
     */
    getQuery(query) {
      let params;
      if (query) {
        params = new URLSearchParams(query);
      } else {
        params = new URLSearchParams(window.location.search);
      }

      this.selectedLocation = params.get('location') || '';
      this.selectedSport = params.get('sport') || '';
      this.selectedTopic = params.get('topic') || '';

      this.getFirstPage();
    },
    loadMore() {
      this.loading = true;
      axios
        // eslint-disable-next-line max-len
        .get(
          `${process.env.VUE_APP_API_DOMAIN}articles/?limit=${PAGE_COUNT}&offset=${this.offset}${this.queryString}`
        )
        .then(response => {
          this.articles = this.articles.concat(response.data.items);
          this.loading = false;
          this.offset += PAGE_COUNT;
        })
        .catch(() => {
          console.error('Failed to fetch additional articles.');
        });
    },
    resetFilters() {
      this.selectedLocation = '';
      this.selectedSport = '';
      this.selectedTopic = '';
      this.getFirstPage();
    },
    updateQueryAndUrl() {
      const params = new URLSearchParams();
      if (this.selectedLocation) params.set('location', this.selectedLocation);
      if (this.selectedSport) params.set('sport', this.selectedSport);
      if (this.selectedTopic) params.set('topic', this.selectedTopic);
      const queryString = params.toString();
      this.queryString = queryString ? `&${queryString}` : '';

      if (!this.firstLoad) {
        if (queryString) {
          window.history.replaceState({}, '', `?${queryString}`);
        } else {
          window.history.replaceState({}, '', window.location.pathname);
        }
      } else {
        this.firstLoad = false;
      }
    }
  }
};
</script>
