<template>
  <main>
    <section>
      <div class="container py-40">
        <h1 class="heading--headline text-center mb-20">Styleguide</h1>
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Colours</h2>
          <div class="column-group">
            <div class="column w-6/12">
              <div class="bg-black text-white p-10 rounded-4 flex justify-center items-center">
                Black
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-700 text-white p-10 rounded-4 flex justify-center items-center">
                Gray-700
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-600 text-white p-10 rounded-4 flex justify-center items-center">
                Gray-600
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-500 p-10 rounded-4 flex justify-center items-center">
                Gray-500
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-400 p-10 rounded-4 flex justify-center items-center">
                Gray-400
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-300 p-10 rounded-4 flex justify-center items-center">
                Gray-300
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-200 p-10 rounded-4 flex justify-center items-center">
                Gray-200
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-gray-100 p-10 rounded-4 flex justify-center items-center">
                Gray-100
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-blue p-10 rounded-4 flex justify-center items-center">Blue</div>
            </div>
            <div class="column w-6/12">
              <div class="bg-blue-dark text-white p-10 rounded-4 flex justify-center items-center">
                Dark Blue
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-green text-white p-10 rounded-4 flex justify-center items-center">
                Green
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-green-dark text-white p-10 rounded-4 flex justify-center items-center">
                Dark Green
              </div>
            </div>
            <div class="column w-6/12">
              <div class="bg-lime p-10 rounded-4 flex justify-center items-center">Lime</div>
            </div>
            <div class="column w-6/12">
              <div class="bg-lime-dark text-white p-10 rounded-4 flex justify-center items-center">
                Dark Lime
              </div>
            </div>
          </div>
        </div>
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Icons</h2>
          <h3 class="subheading mb-10">Base</h3>
          <div class="mb-16">
            <div class="column-group justify-center">
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconSearch class="h-20 mb-4 text-blue-dark" />
                  <span class="text-12">Search</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconUser class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">User</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconArrow class="w-14 mb-4 text-blue-dark" />
                  <span class="text-12">Arrow Down</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconArrow class="w-14 mb-4 transform rotate-90 text-blue-dark" />
                  <span class="text-12">Arrow Left</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconArrow class="w-14 mb-4 transform -rotate-90 text-blue-dark" />
                  <span class="text-12">Arrow Right</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconArrow class="w-14 mb-4 transform rotate-180 text-blue-dark" />
                  <span class="text-12">Arrow Up</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconCross class="w-14 mb-4 transform rotate-45 text-blue-dark" />
                  <span class="text-12">Plus</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconMinus class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Minus</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconCross class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Close</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconMenu class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Menu</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconMenuClose class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Menu Close</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconQuote class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Quote</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12 lg:w-2/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconTick class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Tick</span>
                </div>
              </div>
            </div>
          </div>
          <h3 class="subheading mb-10">Large</h3>
          <div class="mb-16">
            <div class="column-group">
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconDownload class="w-40 mb-4 text-blue-dark" />
                  <span class="text-12">Download</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconHeart class="w-60 mb-4 text-blue-dark" />
                  <span class="text-12">Heart</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconLock class="w-40 mb-4 text-blue-dark" />
                  <span class="text-12">Lock</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconPlay class="w-60 mb-4 text-blue-dark" />
                  <span class="text-12">Play</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconRibbon class="w-40 mb-4 text-blue-dark" />
                  <span class="text-12">Ribbon</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconSearchUp class="w-40 mb-4 text-blue-dark" />
                  <span class="text-12">Search Up</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconStar class="w-60 mb-4 text-blue-dark" />
                  <span class="text-12">Star</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconVolunteer class="w-60 mb-4 text-blue-dark" />
                  <span class="text-12">Volunteer</span>
                </div>
              </div>
            </div>
          </div>
          <h3 class="subheading mb-10">Social</h3>
          <div>
            <div class="column-group">
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconFacebook class="w-12 mb-4 text-blue-dark" />
                  <span class="text-12">Facebook</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconX class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Twitter</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconYoutube class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Youtube</span>
                </div>
              </div>
              <div class="column w-6/12 md:w-3/12">
                <div class="p-30 h-full bg-gray-300 flex flex-col justify-center items-center">
                  <IconInsta class="w-20 mb-4 text-blue-dark" />
                  <span class="text-12">Instagram</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Spacing</h2>
          <p class="mb-4">sm: 30, lg: 60, xl: 80</p>
          <div class="bg-black pad-b-80 mb-10 w-6/12"></div>
          <p class="mb-4">sm: 30, lg: 40, xl: 60</p>
          <div class="bg-black pad-b-60 mb-10 w-6/12"></div>
          <p class="mb-4">sm: 30, lg: 40</p>
          <div class="bg-black pad-b-40 mb-10 w-6/12"></div>
          <p class="mb-4">all: 30</p>
          <div class="bg-black pb-30 mb-10 w-6/12"></div>
          <p class="mb-4">all: 20</p>
          <div class="bg-black pb-20 mb-10 w-6/12"></div>
          <p class="mb-4">all: 16</p>
          <div class="bg-black pb-16 mb-10 w-6/12"></div>
          <p class="mb-4">all: 10</p>
          <div class="bg-black pb-10 mb-10 w-6/12"></div>
        </div>
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Font Styles</h2>
          <h1 class="heading--headline mb-16">Display Heading</h1>
          <h2 class="heading--page mb-16">Page Heading</h2>
          <h3 class="heading--section mb-16">Section Heading</h3>
          <h4 class="subheading mb-16">Subheading</h4>
          <p>
            Paragraph with a
            <a href="#" class="text-link--dark">text link</a>
            .
          </p>
          <p class="bg-gray-600 p-4 text-white">
            Another paragraph with a
            <a href="#" class="text-link--light">text link</a>
            .
          </p>
        </div>
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Buttons</h2>
          <ButtonRound text="Extra Large" colour="lime" size="xlarge" class="mr-16" />
          <ButtonRound text="Large" colour="blue" size="large" class="mr-16" />
          <ButtonRound text="Medium" class="mr-16" />
          <ButtonRound text="Small" size="small" />
        </div>
      </div>
    </section>
    <TabbedSection />
    <section class="pt-40">
      <div class="container">
        <div class="space-b-80">
          <h2 class="heading--section mb-16">Components</h2>
          <h3 class="subheading mb-10">Button Paragraph</h3>
          <div class="column-row-group mb-16">
            <div class="column-row lg:w-1/3">
              <ButtonParagraph
                heading="Button Paragraph"
                paragraph="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
                url="https://www.example.com"
                class="mb-8 lg:mb-0"
              />
            </div>
            <div class="column-row lg:w-1/3">
              <ButtonParagraph
                heading="Button Paragraph"
                paragraph="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
                url="https://www.example.com"
                class="mb-8 lg:mb-0"
              />
            </div>
            <div class="column-row lg:w-1/3">
              <ButtonParagraph
                heading="Button Paragraph"
                paragraph="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>"
                url="https://www.example.com"
              />
            </div>
          </div>
          <h3 class="subheading mb-10">Document Download</h3>
          <DocumentDownload
            filesize="56kb"
            filetype="PDF"
            title="Example Document"
            url="https://www.example.com"
            class="mb-16"
          />
          <h3 class="subheading mb-10">Accordions</h3>
          <div class="mb-16">
            <AccordionComponent title="A bunch of example accordions" top-border>
              <p>Any number of accordions can be grouped together</p>
            </AccordionComponent>
            <AccordionComponent title="Accordions can accept any content in their hidden section">
              <p>Accordions take in their content through the slot</p>
            </AccordionComponent>
            <AccordionComponent title="Accordions accept a prop to render the top border">
              <p>The prop is top-border</p>
            </AccordionComponent>
          </div>
          <h3 class="subheading mb-10">Article Cards</h3>
          <div class="column-row-group">
            <div class="column md:w-6/12 lg:w-4/12">
              <ArticleCard
                description="It seemed it was all over for Gympie hockey as the 2000s ticked over.
                             After the glory days of the 80s and 90s, membership had dropped away
                             until clubs had..."
                image-src="/media/images/test-cat.original.jpg"
                publish-date="29th April, 2020"
                title="Gympie and districts hockey association strikes back"
                topic="News & Events"
                url="https://www.example.com"
              />
            </div>
            <div class="column md:w-6/12 lg:w-4/12">
              <ArticleCard
                description="Recently we conducted a survey on how the coronavirus (COVID-19)
                             health crisis is affecting community sports clubs so we can
                             effectively..."
                image-src="/media/images/test-cat.original.jpg"
                publish-date="29th April, 2020"
                title="Survey reveals impact of Corvid-19 on Good Sports clubs"
                topic="News & Events"
                url="https://www.example.com"
              />
            </div>
            <div class="column md:w-6/12 lg:w-4/12">
              <ArticleCard
                description="Volunteers are the beating heart of local sports clubs. We know that
                             if they didn’t put in the passion they do – our sporting worlds
                             wouldn’t go round! Darren Rigg of Montmorency Junior Football Club..."
                image-src="/media/images/test-cat.original.jpg"
                publish-date="29th April, 2020"
                title="Super-volunteer Darren Rigg"
                topic="News & Events"
                url="https://www.example.com"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-200">
      <div class="container py-30">
        <h3 class="heading--section mb-30">Related Articles</h3>
        <div class="py-15 border-b border-t border-gray-400 mb-40 md:mb-60">
          <RelatedArticle
            description="It seemed it was all over for Gympie hockey as the 2000s ticked over.
                         After the glory days of the 80s and 90s, membership had dropped away
                         until clubs had..."
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Gympie and districts hockey association strikes back"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
          <RelatedArticle
            description="Recently we conducted a survey on how the coronavirus (COVID-19)
                         health crisis is affecting community sports clubs so we can
                         effectively..."
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Survey reveals impact of Corvid-19 on Good Sports clubs"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
          <RelatedArticle
            description="Volunteers are the beating heart of local sports clubs. We know that
                         if they didn’t put in the passion they do – our sporting worlds
                         wouldn’t go round! Darren Rigg of Montmorency Junior Football Club..."
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Super-volunteer Darren Rigg"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
        </div>
        <h3 class="heading--section mb-30">Related Resources</h3>
        <div class="py-15 border-b border-t border-gray-400 mb-40 md:mb-60">
          <RelatedResource
            colour="blue"
            description="It seemed it was all over for Gympie hockey as the 2000s ticked over.
                         After the glory days of the 80s and 90s, membership had dropped away
                         until clubs had..."
            :file-count="3"
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Gympie and districts hockey association strikes back"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
          <RelatedResource
            colour="green"
            description="Recently we conducted a survey on how the coronavirus (COVID-19)
                         health crisis is affecting community sports clubs so we can
                         effectively..."
            :file-count="5"
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Survey reveals impact of Corvid-19 on Good Sports clubs"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
          <RelatedResource
            colour="lime"
            description="Volunteers are the beating heart of local sports clubs. We know that
                         if they didn’t put in the passion they do – our sporting worlds
                         wouldn’t go round! Darren Rigg of Montmorency Junior Football Club..."
            :file-count="1"
            image-src="/media/images/test-cat.original.jpg"
            publish-date="29th April, 2020"
            title="Super-volunteer Darren Rigg"
            topic="News & Events"
            url="https://www.example.com"
            class="py-15"
          />
        </div>
      </div>
    </section>
    <section class="py-40">
      <div class="container">
        <StoryTellingBlock />
      </div>
    </section>
  </main>
</template>

<script>
import AccordionComponent from '@/components/global/AccordionComponent.vue';
import ArticleCard from '@/components/cards/ArticleCard.vue';
import ButtonParagraph from '@/components/blocks/ButtonParagraph.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import DocumentDownload from '@/components/blocks/DocumentDownload.vue';
import RelatedArticle from '@/components/cards/RelatedArticle.vue';
import RelatedResource from '@/components/cards/RelatedResource.vue';
import StoryTellingBlock from '@/components/home/StoryTellingBlock.vue';
import TabbedSection from '@/components/global/TabbedSection.vue';
import IconArrow from '@/components/svg/IconArrow.vue';
import IconCross from '@/components/svg/IconCross.vue';
import IconDownload from '@/components/svg/IconDownload.vue';
import IconFacebook from '@/components/svg/IconFacebook.vue';
import IconHeart from '@/components/svg/IconHeart.vue';
import IconInsta from '@/components/svg/IconInsta.vue';
import IconLock from '@/components/svg/IconLock.vue';
import IconMenu from '@/components/svg/IconMenu.vue';
import IconMenuClose from '@/components/svg/IconMenuClose.vue';
import IconMinus from '@/components/svg/IconMinus.vue';
import IconPlay from '@/components/svg/IconPlay.vue';
import IconQuote from '@/components/svg/IconQuote.vue';
import IconRibbon from '@/components/svg/IconRibbon.vue';
import IconSearch from '@/components/svg/IconSearch.vue';
import IconSearchUp from '@/components/svg/IconSearchUp.vue';
import IconStar from '@/components/svg/IconStar.vue';
import IconTick from '@/components/svg/IconTick.vue';
import IconX from '@/components/svg/IconX.vue';
import IconUser from '@/components/svg/IconUser.vue';
import IconVolunteer from '@/components/svg/IconVolunteer.vue';
import IconYoutube from '@/components/svg/IconYoutube.vue';

export default {
  name: 'StyleGuide',
  components: {
    ArticleCard,
    AccordionComponent,
    ButtonParagraph,
    ButtonRound,
    DocumentDownload,
    RelatedArticle,
    RelatedResource,
    StoryTellingBlock,
    TabbedSection,
    IconArrow,
    IconCross,
    IconDownload,
    IconFacebook,
    IconHeart,
    IconInsta,
    IconLock,
    IconMenu,
    IconMenuClose,
    IconMinus,
    IconPlay,
    IconQuote,
    IconRibbon,
    IconSearch,
    IconSearchUp,
    IconStar,
    IconTick,
    IconX,
    IconUser,
    IconVolunteer,
    IconYoutube
  }
};
</script>

<style></style>
