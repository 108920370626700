<template>
  <svg
    viewBox="0 0 11 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13.314 24H8.437V11.998H6V7.863h2.437V5.38C8.437 2.007 9.812 0 13.722 0h3.256v4.137h-2.035c-1.522 0-1.622.578-1.622 1.656l-.008 2.07H17l-.431 4.135h-3.256V24h.001z"
      transform="translate(-6)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFacebook'
};
</script>
