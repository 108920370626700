<template>
  <svg
    viewBox="0 0 14 9"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M12 16.414L5.293 9.707a.999.999 0 111.414-1.414L12 13.586l5.293-5.293a.999.999 0 111.414 1.414L12 16.414z"
      transform="translate(-5 -8)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrow'
};
</script>
