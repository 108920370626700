<template>
  <div></div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style src="./assets/css/app.css"></style>
