<template>
  <form class="relative">
    <label for="grants-signup" class="sr-only">Grant notifications</label>
    <input
      id="grants-signup"
      type="email"
      name="email"
      placeholder="Please enter your email address"
      required
      class="grants-signup__input"
    />
    <ButtonRound
      colour="lime"
      size="xlarge"
      text="Subscribe"
      type="submit"
      class="w-full md:w-4/12 md:absolute md:right-0 mb-4"
    />
    <input type="checkbox" id="grants-signup-terms" name="terms-agree" v-model="terms" required />
    <label for="grants-signup-terms" class="font-walsheim text-15">
      I agree to the
      <a href="https://adf.org.au/disclaimer/" class="text-link--light">terms & conditions</a>
      .
    </label>
  </form>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'GrantsSignupForm',
  components: { ButtonRound },
  data() {
    return {
      terms: false
    };
  }
};
</script>

<style>
.grants-signup__input {
  @apply px-24 py-12 w-full rounded-24 mb-10 text-center text-black;

  @screen md {
    @apply mb-0 text-left;
    padding-right: calc(100% * 4 / 12);
  }
}
</style>
