<template>
  <svg
    viewBox="0 0 46 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M14.64 42c1.458 0 2.64 1.203 2.64 2.686v12.11l6.818-3.773a2.616 2.616 0 013.58 1.076c.697 1.303.224 2.935-1.057 3.643L15.9 63.674a2.597 2.597 0 01-2.61-.051A2.697 2.697 0 0112 61.313V44.687C12 43.203 13.182 42 14.64 42zm32.706 0C48.812 42 50 43.203 50 44.686v16.628c0 .952-.499 1.834-1.31 2.315a2.63 2.63 0 01-2.635.031l-10.692-6.028a2.703 2.703 0 01-1.027-3.655 2.636 2.636 0 013.61-1.04l6.746 3.805V44.686c0-1.483 1.189-2.686 2.654-2.686zM27.917 1.97c.364 1.398-.5 2.82-1.93 3.176C18.564 6.991 13.375 13.505 13.342 21c.033 7.495 5.222 14.008 12.643 15.854 1.43.355 2.295 1.777 1.931 3.176-.308 1.182-1.394 1.97-2.587 1.97-.218 0-.44-.026-.66-.082C15 39.514 8.204 31.101 8.004 21.368l-.003-.076L8 20.785c0-.052.001-.103.004-.153.2-9.733 6.997-18.146 16.666-20.55 1.43-.356 2.883.49 3.247 1.888zM37.33.082C47 2.486 53.796 10.899 53.996 20.632c.003.05.004.101.004.153v.431c0 .05-.001.102-.004.153-.2 9.732-6.997 18.145-16.666 20.55-.22.055-.442.081-.66.081-1.193 0-2.28-.788-2.587-1.97-.364-1.399.5-2.821 1.93-3.176C43.436 35.008 48.625 28.495 48.658 21c-.033-7.495-5.222-14.008-12.643-15.853-1.43-.356-2.295-1.778-1.931-3.177C34.447.571 35.899-.275 37.33.082zm-4.782 15.212C33.44 15.739 34 16.62 34 17.58v8.837C34 27.844 32.788 29 31.294 29s-2.705-1.156-2.705-2.582v-4.54a2.741 2.741 0 01-2.105-1.104c-.853-1.172-.55-2.78.678-3.594l2.588-1.718a2.816 2.816 0 012.798-.168z"
      transform="translate(-8)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconRibbon'
};
</script>
