<template>
  <article class="flex flex-col h-full">
    <div class="relative pb-16-9 overflow-hidden rounded-30 mb-16">
      <a :href="url" :title="title" class="absolute inset-0 focus:opacity-90 hover:opacity-90">
        <img :src="imageSrc" :alt="imageAlt" class="w-full h-full object-cover" />
      </a>
    </div>
    <p class="text-14 mb-10">{{ subheading }}</p>
    <h3 class="heading--section mb-10">{{ title }}</h3>
    <div class="mb-16" v-html="description" data-test="description"></div>
    <ButtonRound size="small" text="Read more" :url="url" class="mt-auto self-start" />
  </article>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'ArticleCard',
  components: { ButtonRound },
  props: {
    description: String,
    imageAlt: String,
    imageSrc: String,
    publishDate: String,
    title: String,
    topic: String,
    url: String
  },
  computed: {
    subheading() {
      if (this.topic) return `${this.topic} — ${this.publishDate}`;
      return this.publishDate;
    }
  }
};
</script>
