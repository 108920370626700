<template>
  <div
    class="column-row-group lg:justify-between"
    :class="{ 'lg:flex-row-reverse justify-end': imageLeft }"
  >
    <div class="column-row lg:w-6/12 text-left mb-30 lg:mb-0">
      <slot name="text" />
    </div>
    <div class="column-row w-8/12 lg:w-5/12">
      <div class="column-image-height">
        <div class="absolute inset-0">
          <img :src="imageUrl" :alt="imageAlt" class="object-cover h-full pill-radius" />
        </div>
        <div class="absolute w-full h-full pill-radius" :class="[colourClass, shapeOffset]"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoColumnTextImage',
  props: {
    imageAlt: String,
    imageLeft: Boolean,
    imageUrl: String,
    shapeColour: String
  },
  computed: {
    colourClass() {
      return `bg-${this.shapeColour}`;
    },
    shapeOffset() {
      return this.imageLeft ? 'shape-offset--left' : 'shape-offset--right';
    }
  }
};
</script>

<style scoped>
.column-image-height {
  @apply relative;
  padding-bottom: 138%;
}

.pill-radius {
  border-radius: 25.3125rem;
}

.shape-offset--left {
  right: calc(100% + 0.5rem);

  @screen md {
    right: calc(100% + 0.75rem);
  }

  @screen lg {
    right: calc(100% + 2rem);
  }
}

.shape-offset--right {
  left: calc(100% + 0.5rem);

  @screen md {
    left: calc(100% + 0.75rem);
  }

  @screen lg {
    left: calc(100% + 2rem);
  }
}
</style>
