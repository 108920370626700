<template>
  <svg
    viewBox="0 0 22 2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M13.414 12l6.293-6.293a.999.999 0 10-1.414-1.414L12 10.586 10.586 12l-6.293 6.293a.999.999 0 101.414 1.414L12 13.414 13.414 12z"
      transform="rotate(45 24.778 5.293)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMinus'
};
</script>
