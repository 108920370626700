<template>
  <nav v-if="menu.length" class="lg:border-t border-gray-300">
    <div class="flex justify-between border-b border-gray-400 px-20 md:px-24 lg:px-0">
      <h2 class="font-bold py-20">{{ title }}</h2>
      <button class="box-content w-20 h-20 pl-10 py-20 lg:hidden" @click="toggleNav">
        <component :is="buttonIcon" class="text-blue-dark" :class="iconClasses" />
      </button>
    </div>
    <SideNavChild
      v-for="page in menu"
      :key="page.id"
      :page="page"
      class="w-full lg:block"
      :class="{ hidden: !showNav }"
    />
  </nav>
</template>

<script>
import SideNavChild from '@/components/navigation/SideNavChild.vue';
import IconCross from '@/components/svg/IconCross.vue';
import IconMinus from '@/components/svg/IconMinus.vue';

export default {
  name: 'SideNav',
  components: { SideNavChild },
  props: {
    menu: {
      type: Array,
      required: true
    },
    title: String
  },
  data() {
    return {
      showNav: false
    };
  },
  computed: {
    buttonIcon() {
      return this.showChildren ? IconMinus : IconCross;
    },
    iconClasses() {
      return {
        'w-20': this.showChildren,
        'w-14 transform rotate-45': !this.showChildren
      };
    }
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    }
  }
};
</script>
