<template>
  <article class="rounded-30 overflow-hidden md:flex lg:w-8/12">
    <div class="date-closes">
      <span v-if="overrideText">{{ overrideText }}</span>
      <template v-else>
        <span class="px-2 md:text-12 md:mb-6">Closes:</span>
        <span class="px-2 md:text-50">{{ dateParts.day }}</span>
        <span class="px-2 md:leading-normal">{{ dateParts.month }}</span>
        <span class="px-2 md:leading-normal">{{ dateParts.year }}</span>
      </template>
    </div>
    <div class="bg-white p-30 w-full">
      <p v-if="subheading" class="text-14 leading-none mb-10" data-test="subheading">
        {{ subheading }}
      </p>
      <h2 class="heading--page mb-10">{{ title }}</h2>
      <a :href="url" :title="`Apply for grant ${title}`" class="text-link--dark md:text-18">
        Apply now
      </a>
      <p class="mt-16" data-test="description">{{ shortDescription }}</p>
      <div v-show="hasDetails && showDetails" data-test="details" class="mt-10">
        <span class="rich-text" v-html="detailDescription" />
        <p v-if="startDate" class="mt-16">
          Opens:
          <time :datetime="formatDatetime(startDate)">{{ formatDate(startDate) }}</time>
        </p>
        <p v-if="endDate" class="mt-8">
          <strong>
            Closes:
            <time :datetime="formatDatetime(endDate)">{{ formatDate(endDate) }}</time>
          </strong>
        </p>
      </div>
      <ButtonRound
        v-if="hasDetails"
        size="small"
        :text="buttonText"
        class="mt-20"
        @click.native="toggleDetails()"
      >
        <IconArrow class="h-8 ml-4" :class="{ 'transform rotate-180 mb-2': showDetails }" />
      </ButtonRound>
    </div>
  </article>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';
import IconArrow from '@/components/svg/IconArrow.vue';

export default {
  name: 'GrantComponent',
  components: { ButtonRound, IconArrow },
  props: {
    detailDescription: String,
    endDate: String,
    location: Object,
    overrideText: String,
    shortDescription: String,
    startDate: String,
    title: String,
    topic: Object,
    url: String
  },
  data() {
    return {
      showDetails: false
    };
  },
  computed: {
    buttonText() {
      return this.showDetails ? 'Close Details' : 'View Details';
    },
    dateParts() {
      if (!this.endDate) return {};
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      };
      const dateTimeFormat = new Intl.DateTimeFormat('en-AU', options).formatToParts(
        new Date(this.endDate)
      );

      return {
        day: dateTimeFormat[0].value,
        month: dateTimeFormat[2].value,
        year: dateTimeFormat[4].value
      };
    },
    hasDetails() {
      return this.detailDescription || this.endDate;
    },
    subheading() {
      if (this.topic && this.location) return `${this.topic.name} - ${this.location.name}`;
      if (this.topic) return this.topic.name;
      if (this.location) return this.location.name;
      return '';
    }
  },
  methods: {
    formatDate(date) {
      const formatter = new Intl.DateTimeFormat('en-AU', {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
      return formatter.format(new Date(date)).slice(5);
    },
    formatDatetime(date) {
      const formatter = new Intl.DateTimeFormat('en-AU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      const dateParts = formatter.formatToParts(new Date(date));
      return `${dateParts[4].value}-${dateParts[2].value}-${dateParts[0].value}`;
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    }
  }
};
</script>

<style>
.date-closes {
  @apply flex justify-center px-30 py-16 font-gotham font-medium uppercase leading-none;

  @screen md {
    @apply flex-col items-center flex-grow-0;
    width: 120px;
  }
}

article {
  & .date-closes {
    @apply bg-green;
  }

  &:nth-child(3n + 1) .date-closes {
    @apply bg-blue;
  }

  &:nth-child(3n + 2) .date-closes {
    @apply bg-lime;
  }
}
</style>
