<template>
  <div class="overflow-x-hidden">
    <div class="container relative">
      <div class="header-image--height page-header__shape--main">
        <img v-bind="image" sizes="100vw" class="w-full h-full object-cover" />
      </div>
      <div class="header-image--height page-header__shape--secondary" :class="shapeColour"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderImage',
  props: {
    colour: {
      type: String,
      default: 'blue'
    },
    image: {
      type: Object, // "srcset", "src", "alt" properties
      required: true
    }
  },
  computed: {
    shapeColour() {
      return `bg-${this.colour}`;
    }
  }
};
</script>
