<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M37.364 63.512a2.618 2.618 0 01-.472-5.193 26.77 26.77 0 000-52.637 2.618 2.618 0 11.95-5.149 32.006 32.006 0 010 62.934 2.638 2.638 0 01-.478.045zM26.665 63.517a2.608 2.608 0 01-.476-.044 32.006 32.006 0 010-62.946 2.618 2.618 0 11.945 5.15 26.77 26.77 0 000 52.647 2.618 2.618 0 01-.469 5.193z"
    />
    <path
      d="M31.216 37.546a2.556 2.556 0 01-2.506-2.274l-.464-3.156a2.29 2.29 0 012.134-2.784c4.038-.51 5.894-1.764 5.894-4.038v-.092c0-2.089-1.578-3.295-4.27-3.295a8.616 8.616 0 00-5.383 1.856 3.561 3.561 0 01-2.088.742 3.289 3.289 0 01-3.249-3.248 3.245 3.245 0 011.207-2.506 14.544 14.544 0 019.653-3.342c6.682 0 11.23 3.435 11.23 9.607v.093c0 5.893-4.083 8.307-9.142 9.327l-.14.836a2.595 2.595 0 01-2.505 2.274zm0 3.063a3.906 3.906 0 014.037 3.944v.093a4.039 4.039 0 01-8.075 0v-.093a3.906 3.906 0 014.038-3.944z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconQuestion'
};
</script>
