<template>
  <a :href="url" target="_blank" :aria-label="ariaLabel()" class="document-download">
    <div class="document-download__content">
      <p class="text-17">{{ title }}</p>
      <p class="text-15 leading-none">{{ fileInfo() }}</p>
    </div>
    <div class="document-download__icon">
      <div class="p-12 border-2 border-white rounded-full">
        <IconDownload class="w-24 h-24 text-white" />
      </div>
    </div>
  </a>
</template>

<script>
import IconDownload from '@/components/svg/IconDownload.vue';

export default {
  name: 'DocumentDownload',
  components: { IconDownload },
  props: {
    filesize: String,
    filetype: String,
    title: String,
    url: String
  },
  methods: {
    ariaLabel() {
      return `Download file: ${this.title}`;
    },
    fileInfo() {
      return `${this.filetype} ${this.filesize.replace('&nbsp;', ' ')}`;
    }
  }
};
</script>

<style>
.document-download {
  @apply flex;

  &:focus {
    @apply outline-none;

    & .document-download__content {
      @apply border-blue-dark;
    }
  }

  &:hover {
    & .document-download__icon {
      @apply bg-blue;
    }
  }
}

.document-download__content {
  @apply p-20 pt-16 bg-gray-300 border-2 border-r-0 border-gray-300 rounded-l-4 flex-auto;
}

.document-download__icon {
  @apply p-16 bg-blue-dark rounded-r-4 transition-colors duration-150;
}
</style>
