<template>
  <div class="relative flex flex-wrap justify-between">
    <a :href="page.url" class="nav__link--primary" :class="{ 'text-blue-dark': showChildren }">
      <span>{{ page.title }}</span>
      <IconArrow v-if="page.children.length" class="w-14 ml-6 tw-hidden xls:block" />
    </a>
    <button v-if="page.children.length" class="px-8 xls:hidden" @click="toggleChildPages">
      <IconArrow class="w-14 text-blue-dark" :class="{ 'transform rotate-180': showChildren }" />
    </button>
    <div v-if="page.children.length" class="nav__submenu-wrapper" :class="{ block: showChildren }">
      <div class="nav__submenu">
        <a
          v-for="child in page.children"
          :key="child.id"
          :href="child.url"
          class="nav__link--secondary"
        >
          {{ child.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/components/svg/IconArrow.vue';

export default {
  name: 'NavLink',
  components: { IconArrow },
  props: {
    page: Object
  },
  data() {
    return {
      showChildren: false
    };
  },
  methods: {
    toggleChildPages() {
      this.showChildren = !this.showChildren;
    }
  }
};
</script>

<style>
.nav__link--primary {
  @apply py-20 font-gotham font-medium text-gray-600 leading-none;

  & span {
    @apply border-b-2 border-transparent;
  }

  &:focus,
  &:hover {
    @apply text-blue-dark;

    & span {
      @apply border-blue-dark;
    }
  }

  @screen xls {
    @apply flex py-0;

    &:focus,
    &:hover {
      & ~ .nav__submenu-wrapper {
        @apply visible opacity-100;
      }
    }
  }
}

.nav__submenu-wrapper {
  @apply w-full hidden;

  @screen xls {
    @apply block absolute invisible opacity-0 w-auto pt-30 border-t border-transparent;
    left: -30px;
    top: 100%;
    transition: opacity 200ms ease-in, visibility 200ms ease-in;

    &:focus-within,
    &:hover {
      @apply visible opacity-100;
    }
  }
}

.nav__submenu {
  @apply flex flex-col;

  @screen xls {
    @apply shadow-menu bg-white pt-20 pr-40 pb-10 pl-30 rounded-b-30;
  }
}

.nav__link--secondary {
  @apply inline-block mb-20 font-walsheim text-gray-600;

  &:focus,
  &:hover {
    @apply underline text-blue-dark;
  }

  @screen xls {
    @apply py-0 whitespace-no-wrap;
  }
}
</style>
