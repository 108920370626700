<template>
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M15.571.298c.469-.469 1.324-.374 1.91.212.585.586.68 1.44.212 1.909l-6.577 6.576 6.577 6.576c.439.44.383 1.218-.109 1.797l-.104.112c-.585.586-1.44.681-1.909.213l-6.576-6.577-6.576 6.577c-.44.439-1.218.383-1.796-.109L.51 17.48c-.586-.585-.68-1.44-.212-1.909l6.576-6.576L.298 2.419c-.44-.44-.384-1.218.108-1.796L.51.51c.586-.586 1.44-.68 1.909-.212l6.576 6.576z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenuClose'
};
</script>
