<template>
  <article class="column-row-group">
    <div class="column-row mb-16 md:mb-0 md:w-6/12 lg:w-5/12">
      <div class="relative pb-16-9 overflow-hidden rounded-30">
        <a
          :href="url"
          :title="title"
          class="absolute inset-0 focus:opacity-90 hover:opacity-90"
          :class="colourClass"
        >
          <img :src="imageSrc" :alt="imageAlt" class="w-full h-full object-cover" />
        </a>
      </div>
    </div>
    <div class="column-row flex flex-col md:w-6/12 lg:w-7/12">
      <div class="flex-auto flex flex-col justify-center content-limit">
        <p class="text-14 mb-10">{{ subheading }}</p>
        <h3 class="heading--section mb-10">{{ title }}</h3>
        <div data-test="description" v-html="description" />
      </div>
      <ButtonRound
        size="small"
        text="Read more"
        :url="url"
        class="mt-16 md:mt-10 lg:mt-0 self-start lg:self-end"
      />
    </div>
  </article>
</template>

<script>
import ButtonRound from '@/components/global/ButtonRound.vue';

export default {
  name: 'RelatedArticle',
  components: { ButtonRound },
  props: {
    colour: {
      type: String,
      default: 'blue'
    },
    description: String,
    imageAlt: String,
    imageSrc: String,
    publishDate: String,
    title: String,
    topic: String,
    url: String
  },
  computed: {
    colourClass() {
      return `bg-${this.colour}`;
    },
    subheading() {
      if (this.topic) return `${this.topic} — ${this.publishDate}`;
      return this.publishDate;
    }
  }
};
</script>

<style>
.content-limit {
  @screen lg {
    max-width: calc(100% * 5 / 7);
  }
}
</style>
