<template>
  <component :is="iconLoader" :class="iconColour" />
</template>

<script>
import IconDownload from '@/components/svg/IconDownload.vue';
import IconFolder from '@/components/svg/IconFolder.vue';
import IconForm from '@/components/svg/IconForm.vue';
import IconGroup from '@/components/svg/IconGroup.vue';
import IconHand from '@/components/svg/IconHand.vue';
import IconHeart from '@/components/svg/IconHeart.vue';
import IconLock from '@/components/svg/IconLock.vue';
import IconMentalHealth from '@/components/svg/IconMentalHealth.vue';
import IconMoney from '@/components/svg/IconMoney.vue';
import IconPerson from '@/components/svg/IconPerson.vue';
import IconPlay from '@/components/svg/IconPlay.vue';
import IconQuestion from '@/components/svg/IconQuestion.vue';
import IconRibbon from '@/components/svg/IconRibbon.vue';
import IconSearch from '@/components/svg/IconSearch.vue';
import IconStar from '@/components/svg/IconStar.vue';
import IconVolunteer from '@/components/svg/IconVolunteer.vue';

const icons = {
  'download': 'IconDownload',
  'folder': 'IconFolder',
  'form': 'IconForm',
  'group': 'IconGroup',
  'hand': 'IconHand',
  'heart': 'IconHeart',
  'lock': 'IconLock',
  'mental-health': 'IconMentalHealth',
  'money': 'IconMoney',
  'person': 'IconPerson',
  'play': 'IconPlay',
  'question': 'IconQuestion',
  'ribbon': 'IconRibbon',
  'star': 'IconStar',
  'volunteer': 'IconVolunteer',
  'search': 'IconSearch'
};

export default {
  name: 'IconComponent',
  components: {
    IconDownload,
    IconFolder,
    IconForm,
    IconGroup,
    IconHand,
    IconHeart,
    IconLock,
    IconMentalHealth,
    IconMoney,
    IconPerson,
    IconPlay,
    IconQuestion,
    IconRibbon,
    IconSearch,
    IconStar,
    IconVolunteer
  },
  props: {
    colour: String,
    icon: String
  },
  computed: {
    iconColour() {
      return `text-${this.colour}`;
    },
    iconLoader() {
      return icons[this.icon];
    }
  }
};
</script>
