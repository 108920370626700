<template>
  <svg viewBox="0 0 62.4 62.4" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.09,0 C13.94,0 0,13.95 0,31.1 c 0,17.15 13.95,31.1 31.09,31.1 3.15,0 6.25,-0.46 9.19,-1.38 1.5,-0.46 2.33,-2.05 1.87,-3.55 -0.2,-0.66 -0.63,-1.17 -1.16,-1.52 -0.06,-0.62 -0.9,-8.61 -3.6,-13.91 3.07,-2.93 2.38,-9.81 1.84,-13.06 9.7,-1.76 11.72,-6.48 11.95,-7.12 0.32,-0.88 -0.13,-1.84 -1.01,-2.18 -0.88,-0.34 -1.87,0.1 -2.24,0.97 -0.02,0.06 -2.49,5.53 -16.83,5.53 -14.34,0 -15.27,-5.01 -15.32,-5.21 -0.16,-0.92 -1.03,-1.55 -1.96,-1.41 -0.95,0.14 -1.6,1.02 -1.47,1.97 0.1,0.71 1.27,6.16 12.32,7.71 -0.09,2.15 -0.15,6.3 0.48,9.84 0.1,0.55 0.28,1.07 0.52,1.55 -0.13,0.12 -0.25,0.27 -0.34,0.44 C 23.07,45.1 21.51,51.68 20.92,54.41 11.95,50.48 5.67,41.52 5.67,31.12 5.67,17.1 17.08,5.69 31.09,5.69 c 14.01,0 25.43,11.41 25.43,25.43 0,4.84 -1.36,9.54 -3.93,13.59 -0.84,1.32 -0.45,3.07 0.88,3.91 1.32,0.84 3.07,0.44 3.91,-0.88 3.15,-4.97 4.81,-10.72 4.81,-16.63 0,-17.15 -13.95,-31.1 -31.1,-31.1 z m 0,29.43 c 1.7,0 3.26,-0.07 4.69,-0.2 0.64,3.89 0.74,9.18 -0.99,10.26 -1.56,0.97 -3.37,1.2 -4.71,0.6 -0.59,-0.26 -1.33,-0.79 -1.51,-1.83 -0.55,-3.09 -0.51,-6.85 -0.44,-8.9 0.93,0.05 1.92,0.08 2.97,0.08 z m -2.96,13.55 c 0.17,0.09 0.34,0.19 0.52,0.27 0.96,0.43 2,0.64 3.07,0.64 0.86,0 1.73,-0.14 2.6,-0.41 2.1,4.17 2.97,10.54 3.16,12.22 -2.06,0.54 -4.21,0.82 -6.4,0.82 -2.38,0 -4.69,-0.34 -6.88,-0.95 0.44,-2.11 1.89,-8.49 3.92,-12.59 z"
    />
    <path
      d="m 22.93,15.63 c 0,4.5 3.66,8.17 8.16,8.17 4.5,0 8.17,-3.66 8.17,-8.17 0,-4.51 -3.66,-8.16 -8.17,-8.16 -4.51,0 -8.16,3.66 -8.16,8.16 z m 12.86,0 c 0,2.59 -2.11,4.7 -4.69,4.7 -2.58,0 -4.69,-2.11 -4.69,-4.7 0,-2.59 2.11,-4.69 4.69,-4.69 2.58,0 4.69,2.11 4.69,4.69 z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAccessibility'
};
</script>
