/* eslint-disable import/order */

import Vue from 'vue';
import Scrollspy, { Easing } from 'vue2-scrollspy';
import VueScrollTo from 'vue-scrollto';

import AccordionComponent from '@/components/global/AccordionComponent.vue';
import ArticleCard from '@/components/cards/ArticleCard.vue';
import ArticleIndexPage from '@/components/articles/ArticleIndexPage.vue';
import BackToTop from '@/components/global/BackToTop.vue';
import ButtonParagraph from '@/components/blocks/ButtonParagraph.vue';
import ButtonRound from '@/components/global/ButtonRound.vue';
import CallToAction from '@/components/global/CallToAction.vue';
import ClubMap from '@/components/home/ClubMap.vue';
import DocumentDownload from '@/components/blocks/DocumentDownload.vue';
import GrantsCalendar from '@/components/grants/GrantsCalendar.vue';
import GrantsSignup from '@/components/grants/GrantsSignup.vue';
import IconComponent from '@/components/global/IconComponent.vue';
import PageHeaderImage from '@/components/global/PageHeaderImage.vue';
import ParagraphHeader from '@/components/global/ParagraphHeader.vue';
import RelatedArticle from '@/components/cards/RelatedArticle.vue';
import RelatedResource from '@/components/cards/RelatedResource.vue';
import ResourceIndexPage from '@/components/resources/ResourceIndexPage.vue';
import ResourcePageHeader from '@/components/resources/ResourcePageHeader.vue';
import SideNav from './components/navigation/SideNav.vue';
import SiteFooter from './components/global/SiteFooter.vue';
import SiteHeader from './components/navigation/SiteHeader.vue';
import SiteSearch from './components/search/SiteSearch.vue';
import StoryTellingBlock from '@/components/home/StoryTellingBlock.vue';
import StyleGuide from './components/StyleGuide.vue';
import TabAccordion from '@/components/global/TabAccordion.vue';
import TabbedSection from '@/components/global/TabbedSection.vue';
import TwoColumnTextImage from '@/components/blocks/TwoColumnTextImage.vue';

import IconFacebook from '@/components/svg/IconFacebook.vue';
import IconQuote from '@/components/svg/IconQuote.vue';
import IconX from '@/components/svg/IconX.vue';

import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Scrollspy, { easing: Easing.Quadratic.InOut });
Vue.use(VueScrollTo);

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  components: {
    AccordionComponent,
    App,
    ArticleCard,
    ArticleIndexPage,
    BackToTop,
    ButtonParagraph,
    ButtonRound,
    CallToAction,
    ClubMap,
    DocumentDownload,
    GrantsCalendar,
    GrantsSignup,
    IconComponent,
    PageHeaderImage,
    ParagraphHeader,
    RelatedArticle,
    RelatedResource,
    ResourceIndexPage,
    ResourcePageHeader,
    SideNav,
    SiteFooter,
    SiteHeader,
    SiteSearch,
    StoryTellingBlock,
    StyleGuide,
    TabAccordion,
    TabbedSection,
    TwoColumnTextImage,
    IconFacebook,
    IconQuote,
    IconX
  }
});
