<template>
  <a :href="url" class="button-paragraph">
    <h2 class="subheading mb-10">{{ heading }}</h2>
    <div v-html="paragraph"></div>
  </a>
</template>

<script>
export default {
  name: 'ButtonParagraph',
  props: {
    heading: String,
    paragraph: String,
    url: String
  }
};
</script>

<style>
.button-paragraph {
  @apply block border-2 border-blue-dark rounded-20 py-30 px-40;
  @apply transition-colors duration-150;

  & h4 {
    @apply text-blue-dark;
  }

  &:focus {
    @apply bg-gray-300 outline-none;
  }

  &:hover {
    @apply bg-blue-dark text-white;

    & h4 {
      @apply text-white underline;
    }
  }
}
</style>
