<template>
  <svg
    viewBox="0 0 24 21"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M1.2 3h21.6c.663 0 1.2-.672 1.2-1.5S23.463 0 22.8 0H1.2C.537 0 0 .672 0 1.5S.537 3 1.2 3zm21.6 6H1.2C.537 9 0 9.672 0 10.5S.537 12 1.2 12h21.6c.663 0 1.2-.672 1.2-1.5S23.463 9 22.8 9zm0 9H1.2c-.663 0-1.2.672-1.2 1.5S.537 21 1.2 21h21.6c.663 0 1.2-.672 1.2-1.5s-.537-1.5-1.2-1.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenu'
};
</script>
