<template>
  <div
    class="bg-white border-gray-200 lg:border-t-0 first:rounded-30 overflow-hidden"
    :class="{ 'border-t': !active }"
  >
    <button
      class="tab-accordion__button"
      :class="{ 'bg-gray-200': active }"
      :title="`toggle accordion content for ${title}`"
      :aria-controls="`accordion ${title}`"
      :aria-expanded="this.active.toString()"
      @click="toggle()"
    >
      <span>
        <strong>{{ title }}</strong>
      </span>
      <IconArrow aria-hidden="true" class="w-16 transform" :class="{ 'rotate-180': active }" />
    </button>
    <hr v-if="!first" class="container hidden lg:block border-t border-gray-400" />
    <div
      :id="`accordion ${title}`"
      class="container lg:block py-40 lg:py-50"
      :class="{ hidden: !active }"
      data-test="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import IconArrow from '@/components/svg/IconArrow.vue';

export default {
  name: 'TabAccordion',
  props: {
    first: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  components: { IconArrow },
  data() {
    return {
      active: false
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    }
  }
};
</script>

<style>
.tab-accordion__button {
  @apply bg-white px-24 text-left w-full rounded-30;
  @apply font-gotham text-blue-dark flex justify-between items-center;
  padding-bottom: 2.25rem;
  padding-top: 2.25rem;

  @screen lg {
    @apply hidden;
  }
}
</style>
